import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { UniqueDeviceID } from '@awesome-cordova-plugins/unique-device-id/ngx';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { Drivers, Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

import { Market } from '@awesome-cordova-plugins/market/ngx';

import { AdsenseModule } from 'ng2-adsense';

import { LanguageProvider } from './providers/language';
import { AgentsService } from './providers/crm-agent-provider';
import { EncriptionService } from './providers/encription.service';
import { GlobalDataService } from './providers/global-data.service';
import { GuestService } from './providers/guest.service';
import { EmailServices } from './providers/email-services';
import { UserCollectionsServices } from './providers/user-collection-services';
import { GroupServices } from './providers/group-services';
import { ErrorHandlingProvider } from './providers/error-handling';
import { DataProvider } from './providers/data';
import { FileServices } from './providers/files-provider';
import { MessagingServicesProvider } from './providers/messaging-services';
import { OneSignalApi } from './providers/onesignal-services';
import { AdsServicesService } from './providers/ads-services.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdvertizingImpressionComponent } from 'src/app/components/advertizing-impression/advertizing-impression.component';
import { AdvertizingPrizeComponent } from 'src/app/components/advertizing-prize/advertizing-prize.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    AdvertizingImpressionComponent,
    AdvertizingPrizeComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    IonicStorageModule.forRoot({
      name: '__exmedb',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    AdsenseModule.forRoot({
      adClient: 'ca-pub-8915683900096700',
      adSlot: 2912405580,
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [
    UniqueDeviceID,
    Badge,
    Globalization,
    GoogleAnalytics,
    Market,
    AppRate,
    LanguageProvider,
    AgentsService,
    EncriptionService,
    GlobalDataService,
    GuestService,
    EmailServices,
    UserCollectionsServices,
    GroupServices,
    ErrorHandlingProvider,
    DataProvider,
    FileServices,
    MessagingServicesProvider,
    OneSignalApi,
    AdsServicesService,
    Storage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
