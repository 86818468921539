import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SvgPreloadService {
  private svgContents: { [key: string]: string } = {};

  constructor(private http: HttpClient) {}

  preloadSvg(path: string, key: string): Observable<string> {
    return this.http.get(path, { responseType: 'text' }).pipe(
      tap(content => this.svgContents[key] = content)
    );
  }

  getSvgContent(key: string): string {
    return this.svgContents[key];
  }
}
