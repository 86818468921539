import { Component, Input, OnInit } from '@angular/core';
import { UserCollectionsServices } from 'src/app/providers/user-collection-services';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-advertizing-prize',
  templateUrl: './advertizing-prize.component.html',
  styleUrls: ['./advertizing-prize.component.scss'],
})
export class AdvertizingPrizeComponent implements OnInit {
  @Input() isVisible = false;
  @Input() prizeImage = '';
  @Input() prizeDescription = '';
  @Input() prizeURL = '';
  @Input() prizeURLText = '';
  @Input() advertizementCfgPrizeId = ''; // exme_mkt_cfg_col_prize_id
  @Input() advertizementPrizeLogId = ''; //exme_mkt_col_prize_log_id
  @Input() agentId;

  constructor(private userColectionsService: UserCollectionsServices) {}

  ngOnInit() {}

  public openPrizeWithSystemBrowser(url: string) {
    // eslint-disable-next-line max-len
    console.log(
      'Logging Click for: AgentId=' +
        this.agentId +
        ' advertizementCfgPrizeId=' +
        this.advertizementCfgPrizeId +
        ' advertizementPrizeLogId=' +
        this.advertizementPrizeLogId
    );
    console.log('url:' + url);
    this.userColectionsService
      .notifyPrizeClick(
        this.agentId,
        this.advertizementCfgPrizeId,
        this.advertizementPrizeLogId
      )
      .subscribe((res) => {
        Browser.open({ url: url });
      });
  }

  closePrize() {
    this.isVisible = false;
  }
}
