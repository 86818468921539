/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import { Storage } from '@ionic/storage';
import {HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { GlobalDataService } from './global-data.service';

@Injectable()
export class MessagingServicesProvider {


    userLogged: any;
    headers: HttpHeaders;
    url: string;

    constructor(
                  public http: HttpClient,
                  public localStorage: Storage,
                  public gds: GlobalDataService )
      {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        //this.headers = this.headers.set('X-DreamFactory-API-Key', environment.XDreamFactoryAPIKey);

        this.gds.getAgentId().subscribe(
          (data: any) => {
            if (data === undefined || data===null || data==='') {
              console.log('Skipping Agent Id as data is undefined');
            }
            else {
              console.log('Setting X-Exme-Agent-Id value');
              console.log(data);
              this.headers = this.headerSet(this.headers,'X-Exme-Agent-Id', data.toString());
              console.log('Current Header are');
              console.log(this.headers);
            }
          }
        );

        this.gds.getSessionToken().subscribe(
          (data: string) => {
            if (data === undefined || data===null || data==='') {
              console.log('Skipping Session Token as data is undefined');
            }
            else {
              console.log('X-ExME-Session-Token');
              this.headers = this.headerSet(this.headers,'X-ExME-Session-Token', data);
              console.log('Current Header are');
              console.log(this.headers);
            }
          }
        );
          this.userLogged = localStorage.get('userSession');
      }

      createConversation(entityType: string, entityId: string) {
      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const formattedDate = date+' '+time;

      // eslint-disable-next-line quote-props
      const postBody = {'resource': [ {'entity_type': entityType, 'entity_id': entityId, 'creation_time': formattedDate, 'status': 1, 'modification_time': formattedDate } ] };
      return this.localMakeRequest('createConversation',postBody,'ExMEPublic','createConversation',null,false);
/*
[createConversation]
target=_table/CRM_CONVERSATION
method=post
service=ExMEdb
*/
/*

      console.log('Insert Body...' + JSON.stringify(insertBody));

      let url = environment.apiUrlExme + '_table/CRM_CONVERSATION';

      return this.http.post(url, insertBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }


    associateAgentsToConversation(conversationId: number ,agent1: string,agent2: string, type: string) {
      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const formattedDate = date+' '+time;

      const postBody = {'resource': [{'agent_id':agent2,'conversation_id':conversationId,'counterpart_type':type,'counterpart_id':agent1,'creation_time':formattedDate,'status':1,'modification_time':formattedDate},
                                   {'agent_id':agent1,'conversation_id':conversationId,'counterpart_type':type,'counterpart_id':agent2,'creation_time':formattedDate,'status':1,'modification_time':formattedDate}]};
      return this.localMakeRequest('associateAgentsToConversation',postBody,'ExMEPublic','associateAgentsToConversation',null,false);
/*
[associateAgentsToConversation]
target=_table/CRM_AGENT_CONVERSATION
method=post
service=ExMEdb
*/
/*

      console.log('Insert Body...' + JSON.stringify(insertBody));

      let url = environment.apiUrlExme + '_table/CRM_AGENT_CONVERSATION';

      return this.http.post(url, insertBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

    }

    associateAgentToGroupConversation(conversationId: string ,agent1: string,groupId: number) {
      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const formattedDate = date+' '+time;

      const postBody = {'resource': [{'agent_id': agent1,'conversation_id':conversationId, 'creation_time':formattedDate,'counterpart_type':'G','counterpart_id':groupId,'status':1,'modification_time':formattedDate}]};

      return this.localMakeRequest('associateAgentToGroupConversation',postBody,'ExMESecure','associateAgentToGroupConversation',agent1,false);
/*
[associateAgentToGroupConversation]
target=_table/CRM_AGENT_CONVERSATION
method=post
service=ExMEdb
sessionField=agent_id
*/
/*

      console.log('Insert Body...' + JSON.stringify(insertBody));

      let url = environment.apiUrlExme + '_table/CRM_AGENT_CONVERSATION';

      return this.http.post(url, insertBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

    }

    setAgentConversationLastRead(conversation_id: string ,agentId: string, max_message: number) {
      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const formattedDate = date+' '+time;

      const postBody = { 'theConversationId' : conversation_id , 'theAgentId': agentId, 'resource': [ { 'last_read_message':max_message }] };
      return this.localMakeRequest('setAgentConversationLastRead',postBody,'ExMESecure','setAgentConversationLastRead',agentId,false);
/*
[setAgentConversationLastRead]
target=_table/CRM_AGENT_CONVERSATION
method=patch
service=ExMEdb
sessionField=agent_id
filterField[]=conversation_id##%3D##theConversationId
filterField[]=agent_id##%3D##theAgentId
*/
/*

      console.log('Insert Body...' + JSON.stringify(insertBody));

      let url = environment.apiUrlExme + '_table/CRM_AGENT_CONVERSATION?filter=(conversation_id%3D'+ conversation_id+ ')%20AND%20(agent_id%3D'+agent_id+')';
      console.log("Requesting PATCH for URL:" + url);

      console.log('Insert Body...' + JSON.stringify(insertBody));
      console.log("Requesting PATCH for URL:" + url);

      return this.http.patch(url, insertBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
      */
//        .map((response: Response) => response.json())

    }

//    DROPPED
/*    setTradeRequestConversationId(trade_request_id: number ,conversation_id: number) {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var formattedDate = date+' '+time;

      let insertBody = {
        "resource": [
          {
            "trade_conversation_id":conversation_id,
            "modification_time": formattedDate
          }
        ]
      }

      let url = environment.apiUrlExme + '_table/COL_CD_USER_TRADE_REQUEST?filter=(trade_request_id%3D'+ trade_request_id+ ')';
      console.log('Insert Body...' + JSON.stringify(insertBody));
      console.log("Requesting PATCH for URL:" + url);

      return this.http.patch(url, insertBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );

    }
*/

    getAgentConversationLastRead(conversation_id: string ,agentId: string) {

      const postBody = { 'theConversationId': conversation_id , 'theAgentId' : agentId };
      return this.localMakeRequest('getAgentConversationLastRead',postBody,'ExMESecure','getAgentConversationLastRead',agentId,false);
/*
[getAgentConversationLastRead]
target=_table/CRM_AGENT_CONVERSATION
method=get
service=ExMEdb
sessionField=agent_id
filterField[]=conversation_id##%3D##theConversationId
filterField[]=agent_id##%3D##theAgentId
*/
/*


      let url = environment.apiUrlExme + '_table/CRM_AGENT_CONVERSATION?filter=(conversation_id%3D'+ conversation_id+ ')%20AND%20(agent_id%3D'+agent_id+')';
      console.log("Requesting Get for URL:" + url);
      return this.http.get(url, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

    }

    // MIGRATED TO SECURE
    // AGENT_ID1 will be secured field.
    getAgentsDirectConversation(agent_id1: string ,agent_id2: string) {
/*
      let url = environment.apiUrlExme + '_table/CRM_AGENT_CONVERSATION?filter=(counterpart_type%3D"A")and(counterpart_id%3D'+ agent_id2+ ')%20AND%20(agent_id%3D'+agent_id1+')%20AND%20(status%3D1)&order=conversation_id%20DESC';
      console.log("Requesting Get for URL:" + url);
      return this.http.get(url, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/

      const postBody = {agent_id1, agent_id2};
      return this.localMakeRequest('getAgentsDirectConversation',postBody,'ExMESecure','getAgentsDirectConversation',agent_id1,false);
/*
      console.log('Post Body...' + JSON.stringify(postBody));

      let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
      console.log('Posting to URL: ' + url)
      let localHeader =  this.headers.append('X-Exme-Operation', 'getAgentsDirectConversation').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
      console.log('Headers');
      console.log(localHeader)
      return this.http.post(url, postBody, {headers: localHeader}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
    }

    // MIGRATED TO SECURE
    getUserConversations(agent_id: string ) {

      // let url = environment.apiUrlExme + '_table/vUserMessages?filter=(agent_id%3D'+agent_id+')&order=max_message_date%20DESC';
      //console.log("Requesting Get for URL:" + url);
      //return this.http.get(url, {headers: this.headers}).pipe(
      //  map(this.extractData),
      //  catchError(this.handleError)
      //);

      const postBody = {  agent_id};

      console.log('Post Body...' + JSON.stringify(postBody));
      return this.localMakeRequest('getUserConversations',postBody,'ExMESecure','getUserConversations',agent_id,false);
/*
      let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
      console.log('Posting to URL: ' + url)
      let localHeader =  this.headers.append('X-Exme-Operation', 'getUserConversations').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
      console.log('Headers');
      console.log(localHeader)
      return this.http.post(url, postBody, {headers: localHeader}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );

*/
    }

    createGroupConversation(group_id: number) {
      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const formattedDate = date+' '+time;

      const insertBody = {'resource': [{'entity_type': 'G','entity_id': group_id,'creation_time': formattedDate,'status': 1,'modification_time': formattedDate}]};
      return this.localMakeRequest('createGroupConversation',insertBody,'ExMEPublic','createGroupConversation',null,false);
/*
[createGroupConversation]
target=_table/CRM_CONVERSATION
method=post
service=ExMEdb
*/
/*

      console.log('Insert Body...' + JSON.stringify(insertBody));

      let url = environment.apiUrlExme + '_table/CRM_CONVERSATION';

      return this.http.post(url, insertBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }

    createMessage(conversationId: string, sender_agent_id: string,message: string) {
      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const formattedDate = date+' '+time;

      const insertBody = {'resource': [{ 'conversation_id': conversationId, 'creation_time': formattedDate, sender_agent_id, 'message_text': message, 'modification_time': formattedDate }]};
      return this.localMakeRequest('createMessage',insertBody,'ExMESecure','createMessage',sender_agent_id,false);
/*
[createMessage]
target=_table/CRM_MESSAGE
method=post
service=ExMEdb
sessionField=sender_agent_id
*/
/*

      console.log('Insert Body...' + JSON.stringify(insertBody));

      let url = environment.apiUrlExme + '_table/CRM_MESSAGE';

      return this.http.post(url, insertBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }

    createMessageLink(conversation_id: number, trade_conversation_id: number,sender_agent_id: string,message: string, linked_conversation_icon: string){
      console.log('Provider creating link for:');
      console.log('Provider conversation_id:' + conversation_id);
      console.log('Provider trade_conversation_id:' + trade_conversation_id);
      console.log('Provider sender_agent_id:' + sender_agent_id);
      console.log('Provider message:' + message);
      console.log('Provider linked_conversation_icon:' );
      console.log(linked_conversation_icon);

      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const formattedDate = date+' '+time;

      const insertBody = {'resource': [{conversation_id,'creation_time': formattedDate,sender_agent_id,
                                      'message_text': message,'modification_time': formattedDate,'message_type':'L','linked_conversation':trade_conversation_id,linked_conversation_icon}]};

      return this.localMakeRequest('createMessageLink',insertBody,'ExMESecure','createMessageLink',sender_agent_id,false);
/*
[createMessageLink]
target=_table/CRM_MESSAGE
method=post
service=ExMEdb
sessionField=sender_agent_id
*/
/*
      console.log('Insert Body...' + JSON.stringify(insertBody));

      let url = environment.apiUrlExme + '_table/CRM_MESSAGE';

      return this.http.post(url, insertBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }

    getConversationMessages(conversationId: string, agentId: string) {
      const postBody = { 'theConversationId': conversationId, 'theAgentId': agentId};
      return this.localMakeRequest('getConversationMessages',postBody,'ExMESecure','getConversationMessages',agentId,false);
/*
[getConversationMessages]
target=_table/vCrmMessagesWithLinks
method=get
service=ExMEdb
sessionField=agent_id
orderField=creation_time%20ASC
filterField[]=conversation_id##%3D##theConversationId
limit=50
*/
/*
      // let url = environment.apiUrlExme + '_table/vCrmMessagesWithLinks?filter=conversation_id%3D'+ conversationId+ '&limit=50&order=creation_time%20ASC';
      let url = environment.apiUrlExme + '_table/vCrmMessagesWithLinks?filter=conversation_id%3D'+ conversationId+ '&limit=50&order=message_id%20ASC';
      console.log("Requesting Get for URL:" + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }

    getConversationMessagesContinued(conversationId: string, maxLoadedMessageId: number, agentId: string) {
      const postBody = { 'theConversationId': conversationId, maxLoadedMessageId,'theAgentId': agentId};
      return this.localMakeRequest('getConversationMessagesContinued',postBody,'ExMESecure','getConversationMessagesContinued',agentId,false);
/*
[getConversationMessagesContinued]
target=_table/vCrmMessagesWithLinks
method=get
service=ExMEdb
sessionField=agent_id
orderField=creation_time%20ASC
filterField[]=conversation_id##%3D##theConversationId
filterField[]=message_id##%3E##maxLoadedMessageId
*/
/*
        //let url = environment.apiUrlExme + '_table/vCrmMessagesWithLinks?filter=(conversation_id%3D'+ conversationId+ ')%20AND%20(message_id%3E'+maxLoadedMessageId+')&order=creation_time%20ASC';
      let url = environment.apiUrlExme + '_table/vCrmMessagesWithLinks?filter=(conversation_id%3D'+ conversationId+ ')%20AND%20(message_id%3E'+maxLoadedMessageId+')&order=message_id%20ASC';
      console.log("Requesting Get for URL:" + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }

    // MIGRATED TO SECURE
    getMaxConversationId(conversationId: string, agentId: string) {
/*      let url = environment.apiUrlExme + '_table/vCrmMessagesWithLinks?fields=message_id&filter=((conversation_id%3D'+ conversationId+ ')and(message_type%3D"M"))&order=message_id%20DESC&limit=1';
      console.log("Requesting Get for URL:" + url);
      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/

      const postBody = {
        'conversation_id': conversationId
      };
      return this.localMakeRequest('getConversationMaxId',postBody,'ExmeSecure','getConversationMaxId',agentId,false);

/*      console.log('Post Body...' + JSON.stringify(postBody));

      let url = environment.apiUrl+ 'api/v2/' + 'ExMESecure';
      console.log('Posting to URL: ' + url)
      let localHeader =  this.headers.append('X-Exme-Operation', 'getConversationMaxId').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
      console.log('Headers');
      console.log(localHeader)
      return this.http.post(url, postBody, {headers: localHeader}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );

*/

    }

    localMakeRequest(callFunction: string, body: any , service: string , operation: string, agent_id: string , deleteSession: boolean) {
      console.log('Post Body called from: ' + callFunction);
      console.log(JSON.stringify(body));
  
      const url = environment.apiUrl+ 'api/v2/' + service;
      console.log('Posting to URL: ' + url);
      // AMPM 20230629
      //let localHeader =  this.headers.append('X-Exme-Operation', operation).set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
      let localHeader = this.headerSet(this.headers,'X-Exme-Operation', operation);
      console.log(localHeader);
      localHeader = this.headerSet(localHeader,'X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
      console.log(localHeader);
      if ( deleteSession ) {
        //localHeader = localHeader.delete('X-ExME-Session-Token');
        localHeader = this.headerSet(localHeader,'X-ExME-Session-Token',null);
        console.log(localHeader);
      }
      if ( agent_id != null && localHeader.get('X-Exme-Agent-Id') != agent_id ) {
        //localHeader = localHeader.delete('X-Exme-Agent-Id').set('X-Exme-Agent-Id',agent_id);
        localHeader = this.headerSet(localHeader,'X-Exme-Agent-Id',agent_id);
        console.log(localHeader);
  
      }
      console.log('Headers for call from ' + callFunction + ' are:');
      console.log(localHeader);
      return this.http.post(url, body, {headers: localHeader}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  
    }
  
    private headerSet(sourceHeader: HttpHeaders, changeKey: string, changeValue: any){
      let retHeader=new HttpHeaders();
      sourceHeader.keys().forEach( (key) => {
        if ( key !== changeKey){
          retHeader=retHeader.append(key,sourceHeader.get(key));
        } else {
        }
      });
      if ( changeValue !== undefined && changeValue !== null && changeValue !== '') {
        retHeader=retHeader.append(changeKey,changeValue);
//        console.log('Appending Key');
      }
//      console.log('Returning:');
//      console.log(retHeader);
      return retHeader;
    }

  private handleError(error: HttpErrorResponse) {
    console.log('Entering handleError');
    console.log('Error is:');
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `error message was: ${error.error.error.message}`);
    }
    return throwError('Something bad happened; please try again later.');
  }

  private extractData(res: Response) {
    console.log('entering extractData');
    console.log('res:');
    console.log(res);
    const body = res;
    return body || { };
  }



}
