/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import { Storage } from '@ionic/storage';
import {HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalDataService } from '../providers/global-data.service';

@Injectable()
export class GroupServices {

  userLogged: any;
  headers: HttpHeaders;
  url: string;
  agent: any;

  constructor (
                public http: HttpClient,
                private gds: GlobalDataService,
                public localStorage: Storage )
    {
      this.headers = new HttpHeaders();
      this.headers = this.headers.set('Content-Type', 'application/json');
      //this.headers = this.headers.set('X-DreamFactory-API-Key', environment.XDreamFactoryAPIKey);
      this.gds.getAgentId().subscribe(
        (data: any) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Agent Id as data is undefined');
          }
          else {
            console.log('Setting X-Exme-Agent-Id value');
            console.log(data);
            this.headers = this.headerSet(this.headers,'X-Exme-Agent-Id', data.toString());
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );

      this.gds.getSessionToken().subscribe(
        (data: string) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Session Token as data is undefined');
          }
          else {
            console.log('X-ExME-Session-Token');
            this.headers = this.headerSet(this.headers,'X-ExME-Session-Token', data);
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );

      this.userLogged = localStorage.get('userSession');
      this.agent = localStorage.get('agent');

      this.getSessionToken();
    }

  getSessionToken() {
    this.gds.getSessionToken().subscribe(
      (token: string) => {
        if ( token !== undefined && token !== '' ) {
          this.headers = new HttpHeaders()
          .append('Accept', 'application/json')
          .append('Content-Type', 'application/json' )
          //.append( 'X-DreamFactory-API-Key', environment.XDreamFactoryAPIKey)
          .append('X-ExME-Session-Token', token);
        }
      }
    );
  }



    getUserGroups(agentId: string) {
      // Bring all groups from agent where STATUS > 0

      let body = {'theAgentId': agentId };
      return this.localMakeRequest('getUserGroups',body,'ExMESecure','getUserGroups',agentId,false);

/*
[getUserGroups]
target=_table/vAgentGroups
method=get
service=ExMEdb
relatedField=GroupMembersCount
filterField[]=agent_id##%3D##theAgentId
staticFilter=STATUS%3E0
orderField="status%20desc%2C%20numActionsPending%20desc%2C%20Group_name"
*/
/*
      let url = environment.apiUrlExme + '_table/vAgentGroups?related=GroupMembersCount&filter=(agent_id%3D'
        + agentId + ')%20and%20(STATUS%3E0)&order=status%20desc%2C%20numActionsPending%20desc%2C%20Group_name';

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }
    getUserActiveGroups(agentId: string) {
      // Bring all groups from agent where STATUS > 0

      let body = {'theAgentId': agentId };
      return this.localMakeRequest('getUserActiveGroups',body,'ExMESecure','getUserActiveGroups',agentId,false);

/*
[getUserActiveGroups]
target=_table/vAgentGroupsActive
method=get
service=ExMEdb
relatedField=GroupMembersCount
filterField[]=agent_id##%3D##theAgentId
orderField="status%20desc%2C%20numActionsPending%20desc%2C%20Group_name"
*/
/*

      let url = environment.apiUrlExme + '_table/vAgentGroupsActive?related=GroupMembersCount&filter=(agent_id%3D'
        + agentId + ')&order=status%20desc%2C%20numActionsPending%20desc%2C%20Group_name';

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }

    /*
    getAllGroups(groupType: string) {

      let url = environment.apiUrlExme + '_table/vGroups?filter=group_type%3D&related=GroupMembersCount' + groupType;

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );

//        .map((response: Response) => response.json())
    }
*/
    getGroupMembers(groupId: string, agentId:string) {

      if (groupId == null ) {
        groupId = '-1';
      }
      let body = { 'theGroupId': groupId , 'theAgentId': agentId };
      return this.localMakeRequest('getGroupMembers',body,'ExMEPublic','getGroupMembers',null,false);

/*
[getGroupMembers]
target=_table/vGroupAgents
method=get
service=ExMEdb
filterField[]=ORIG_AGENT_ID##%3D##theAgentId
filterField[]=GROUP_ID##%3D##theGroupId
orderField=STATUS%20desc
relatedField="GroupMembersCount,CRM_AGENT_by_AGENT_ID"
*/
/*

      console.log(groupId);
      let url = environment.apiUrlExme + '_table/vGroupAgents?related=GroupMembersCount,CRM_AGENT_by_AGENT_ID&filter=(ORIG_AGENT_ID%3D'+agentId+')%20and%20(GROUP_ID%3D' + groupId+')&order=STATUS%20desc';
      console.log(url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

    }

    getAgentNotInGroups(agentId: string) {
      // Bring all groups from agent where STATUS > 0
//      let url = environment.apiUrlExme + '_table/vAgentNotInGroups?related=GroupMembersCount&filter=agent_id%3D'+ agentId+'&order=score%20desc';
      let body = { 'theAgentId': agentId };
      return this.localMakeRequest('getAgentNotInGroups',body,'ExMESecure','getAgentNotInGroups',agentId,false);

/*
[getAgentNotInGroups]
target=_table/vAgentNotInGroups
method=get
service=ExMEdb
filterField[]=agent_id##%3D##theAgentId
orderField=score%20desc
*/
/*

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }

    createNewGroup(agentId: number, groupType: string, groupName: string, groupDesc: string, groupCountry: string) {

      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      var formattedDate = date+' '+time;

      let groupBody = {'resource': [{'master_agent_id': agentId, 'group_type': groupType,'GROUP_NAME': groupName,'GROUP_DESCRIPTION': groupDesc,
                                     'GROUP_EMAIL': '','CREATED_BY': 'Group Admin','CREATION_DATE': formattedDate, 'LAST_UPDATE': formattedDate,'UPDATED_BY': 'Group Admin', 'country': groupCountry}]};
      return this.localMakeRequest('createNewGroup',groupBody,'ExMEPublic','createNewGroup',null,false);
/*
[createNewGroup]
target=_table/CRM_GROUP
method=post
service=ExMEdb
*/
/*
      let url = environment.apiUrlExme + '_table/CRM_GROUP';

      return this.http.post(url, groupBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

    }

    deactivateGroup(groupID: number) {

      let paramsBody = {'resource': [ {'GROUP_ID': groupID, 'active': false}]};

      return this.localMakeRequest('deactivateGroup',paramsBody,'ExMEPublic','deactivateGroup',null,false);
/*
[deactivateGroup]
target=_table/CRM_GROUP
method=patch
service=ExMEdb
filterField[]=GROUP_ID##%3D##theGroupId
*/
/*

      let url = environment.apiUrlExme + '_table/CRM_GROUP';

      return this.http.patch(url, groupBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

    }

    updateGroup(groupId: number, groupType: string, groupName: string, groupDesc: string) {

      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      var formattedDate = date+' '+time;

      let paramsBody =  { 'theGroupId': groupId , 'resource': [{'group_type': groupType, 'GROUP_NAME': groupName,'GROUP_DESCRIPTION': groupDesc,'LAST_UPDATE': formattedDate,'UPDATED_BY': 'Manager'}]};
      return this.localMakeRequest('updateGroup',paramsBody,'ExMEPublic','updateGroup',null,false);
/*
[updateGroup]
target=_table/CRM_GROUP
method=patch
service=ExMEdb
filterField[]=GROUP_ID##%3D##theGroupId
*/
/*

      let url = environment.apiUrlExme + '_table/CRM_GROUP?filter=GROUP_ID%3D' + groupId;

      return this.http.patch(url, groupBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

    }

    updateGroupLogo(groupId: number, groupLogo: string) {

      let paramsBody = {'theGroupId': groupId, 'resource': [{'LOGO': groupLogo}]};
      return this.localMakeRequest('updateGroupLogo',paramsBody,'ExMEPublic','updateGroupLogo',null,false);
/*
[updateGroupLogo]
target=_table/CRM_GROUP
method=patch
service=ExMEdb
filterField[]=GROUP_ID##%3D##theGroupId
*/
/*

      let groupLogoBody = {"resource": [{"LOGO": groupLogo}]}

      let url = environment.apiUrlExme + '_table/CRM_GROUP?filter=GROUP_ID%3D' + groupId;

      return this.http.patch(url, groupLogoBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }

    addAgentToGroup (agentId: string, groupId: string, status: number) {

      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      var formattedDate = date+' '+time;

      let paramsBody = {'resource': [{'AGENT_ID': agentId,'GROUP_ID': groupId,'CREATED_BY': 'Group Admin','CREATION_DATE': formattedDate,'LAST_UPDATE': formattedDate,'UPDATED_BY': 'Group Admin',
                                      'USER_TAG': '','STATUS': status}]};
      return this.localMakeRequest('addAgentToGroup',paramsBody,'ExMESecure','addAgentToGroup',agentId,false);
/*
[addAgentToGroup]
target=_table/CRM_AGENT_GROUP
method=post
service=ExMEdb
sessionField=AGENT_ID
*/
/*

      let url = environment.apiUrlExme + '_table/CRM_AGENT_GROUP';

      return this.http.post(url, agentAddedBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())


    }

    callProcToAddAgentToGroup(groupId: string, agentId: string) {

      let paramsBody = {'params': [{'name': 'p_group_id','value':groupId},{'name': 'p_agent_id','value':agentId}]};
      return this.localMakeRequest('callProcToAddAgentToGroup',paramsBody,'ExMESecure','callProcToAddAgentToGroup',agentId,false);
/*
[callProcToAddAgentToGroup]
target=_proc/COL_PROC_ADD_GROUP_AGENT_2
method=proc
service=ExMEdb
sessionField=p_agent_id
*/
/*

      let url = environment.apiUrlExme + '_proc/COL_PROC_ADD_GROUP_AGENT_2'

      return this.http.post(url, paramsBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }

    callInviteMemberToGroup(pemail: string, pgroupid: number) {

      let paramsBody = {'params': [{'name': 'pemail','value':pemail},{'name': 'pgroupid','value':pgroupid}]};
      return this.localMakeRequest('callInviteMemberToGroup',paramsBody,'ExMEPublic','callInviteMemberToGroup',null,false);
/*
[callInviteMemberToGroup]
target=_proc/COL_INVITE_MEMBER_TO_GROUP
method=proc
service=ExMEdb
*/
/*

      let url = environment.apiUrlExme + '_proc/COL_INVITE_MEMBER_TO_GROUP'

      return this.http.post(url, paramsBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }

    callAutoAddToGroup(pUserCollectionId: string, agentId: string) {
      let paramsBody = {'params': [{'name': 'p_user_collection_id','value':pUserCollectionId},{'name': 'p_agent_id','value': agentId }]};
      return this.localMakeRequest('callAutoAddToGroup',paramsBody,'ExMESecure','callAutoAddToGroup',agentId,false);
/*
[callAutoAddToGroup]
target=_proc/COL_INSERT_AUTO_ADD_GROUP
method=proc
service=ExMEdb
sessionField=p_agent_id
*/
/*

      let url = environment.apiUrlExme + '_proc/COL_INSERT_AUTO_ADD_GROUP'

      return this.http.post(url, paramsBody, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())
    }

    promoteMemberToAdmin(agentId: string ,groupId: string, status: number){
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      var formattedDate = date+' '+time;
      let body ={ 'theGroupId': groupId, 'theAgentId': agentId, 'theStatus': status, 'resource':[{'STATUS': 4, 'LAST_UPDATE': formattedDate}]};
      return this.localMakeRequest('promoteMemberToAdmin',body,'ExMESecure','promoteMemberToAdmin',agentId,false);

/*
[promoteMemberToAdmin]
target=_table/CRM_AGENT_GROUP
method=patch
service=ExMEdb
filterField[]=GROUP_ID##%3D##theGroupId
filterField[]=agent_id##%3D##theAgentId
filterField[]=status##%3D##theStatus
*/
/*

      let url = environment.apiUrlExme +
        '_table/CRM_AGENT_GROUP?filter=(GROUP_ID%3D'
        + groupId + ')%20and%20(agent_id%3D' + agent_id + ')%20and%20(status%3D' + status + ')';

      return this.http.patch(url, body, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())


    }
    getOtherAdmins(agentId: string , groupId: string) {
      let body = { 'theGroupId': groupId, 'theAgentId': agentId };
      return this.localMakeRequest('getOtherAdmins',body,'ExMESecure','getOtherAdmins',agentId,false);

/*
[getOtherAdmins]
target=_table/CRM_AGENT_GROUP
method=get
service=ExMEdb
filterField[]=GROUP_ID##%3D##theGroupId
filterField[]=agent_id##%21%3D##theAgentId
relatedField=CRM_AGENT_by_AGENT_ID
staticFilter[]="status%3D4"
*/
/*
      console.log(group_id);
      let url = environment.apiUrlExme + '_table/CRM_AGENT_GROUP?related=CRM_AGENT_by_AGENT_ID&filter=(GROUP_ID%3D'
      + group_id + ')%20and%20(agent_id%21%3D' + agentId + ')%20and%20(status%3D4)';
      console.log(url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json());

    }

    getGroupAdmins( groupId: string) {
      let body = { 'theGroupId': groupId };
      return this.localMakeRequest('getGroupAdmins',body,'ExMEPublic','getGroupAdmins',null,false);

/*
[getGroupAdmins]
target=_table/CRM_AGENT_GROUP
method=get
service=ExMEdb
filterField[]=GROUP_ID##%3D##theGroupId
staticFilter[]="status%3D4"
*/
/*
      console.log(group_id);
      let url = environment.apiUrlExme + '_table/CRM_AGENT_GROUP?filter=(GROUP_ID%3D'
      + group_id + ')%20and%20(status%3D4)';
      console.log(url);

      return this.http.get(url, { headers: this.headers }).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json());

    }

    updateAgentGroupPendingStatus(groupId: string, email: string, status: number) {

      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      var formattedDate = date+' '+time;
      let body = { 'theEmail': email , 'theGroupId': groupId, 'resource':[{'STATUS': status,'LAST_UPDATE': formattedDate}]};
      return this.localMakeRequest('updateAgentGroupPendingStatus',body,'ExMEPublic','updateAgentGroupPendingStatus',null,false);

/*
[updateAgentGroupPendingStatus]
target=_table/CRM_AGENT_GROUP_PENDING
method=patch
service=ExMEdb
sessionField=delegated_agent_id
filterField[]=GROUP_ID##%3D##theGroupId
filterField[]=email##%3D##theEmail
*/
/*

      let url = environment.apiUrlExme +
        '_table/CRM_AGENT_GROUP_PENDING?filter=(GROUP_ID%3D'
        + groupId + ')%20and%20(email%3D' + email + ')';

      return this.http.patch(url, body, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

    }

    updateAgentGroupStatus(groupId: string, agentId: string, status: number) {

      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      var formattedDate = date+' '+time;

      let body = { 'theAgentId': agentId , 'theGroupId': groupId, 'resource':[{'STATUS': status,'LAST_UPDATE': formattedDate}]};
      return this.localMakeRequest('updateAgentGroupStatus',body,'ExMEPublic','updateAgentGroupStatus',null,false);

/*
[updateAgentGroupStatus]
target=_table/CRM_AGENT_GROUP
method=patch
service=ExMEdb
sessionField=delegated_agent_id
filterField[]=GROUP_ID##%3D##theGroupId
filterField[]=agent_id##%3D##theAgentId
*/
/*

      let url = environment.apiUrlExme +
        '_table/CRM_AGENT_GROUP?filter=(GROUP_ID%3D'
        + groupId + ')%20and%20(AGENT_ID%3D' + agentId + ')';

      return this.http.patch(url, body, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
*/
//        .map((response: Response) => response.json())

    }

    updateAgentGroupStatusByEmail(groupId: string,email: string, status: number) {

      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      var formattedDate = date+' '+time;

      let body = { 'theEmail': email , 'theGroupId': groupId, 'resource':[{'STATUS': status,'LAST_UPDATE': formattedDate}]};
      return this.localMakeRequest('updateAgentGroupStatusByEmail',body,'ExMEPublic','updateAgentGroupStatusByEmail',null,false);
  /*
  [updateAgentGroupStatusByEmail]
  target=_table/CRM_AGENT_GROUP
  method=patch
  service=ExMEdb
  sessionField=delegated_agent_id
  orderField=creation_time%20desc
  filterField[]=GROUP_ID##%3D##theGroupId
  filterField[]=email##%3D##theEmail
  */
  /*

      let url = environment.apiUrlExme +
        '_table/CRM_AGENT_GROUP?filter=(GROUP_ID%3D'
        + groupId + ')%20and%20(email%3D' + email + ')';

      return this.http.patch(url, body, {headers: this.headers}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  */
//        .map((response: Response) => response.json())

    }


    localMakeRequest(callFunction: string, body: any , service: string , operation: string, agent_id: string , deleteSession: boolean) {
      console.log('Post Body called from: ' + callFunction);
      console.log(JSON.stringify(body));

      const url = environment.apiUrl+ 'api/v2/' + service;
      console.log('Posting to URL: ' + url);
      // AMPM 20230629
      //let localHeader =  this.headers.append('X-Exme-Operation', operation).set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
      let localHeader = this.headerSet(this.headers,'X-Exme-Operation', operation);
      console.log(localHeader);
      localHeader = this.headerSet(localHeader,'X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
      console.log(localHeader);
      if ( deleteSession ) {
        //localHeader = localHeader.delete('X-ExME-Session-Token');
        localHeader = this.headerSet(localHeader,'X-ExME-Session-Token',null);
        console.log(localHeader);
      }
      if ( agent_id != null && localHeader.get('X-Exme-Agent-Id') != agent_id ) {
        //localHeader = localHeader.delete('X-Exme-Agent-Id').set('X-Exme-Agent-Id',agent_id);
        localHeader = this.headerSet(localHeader,'X-Exme-Agent-Id',agent_id);
        console.log(localHeader);

      }
      console.log('Headers for call from ' + callFunction + ' are:');
      console.log(localHeader);
      return this.http.post(url, body, {headers: localHeader}).pipe(
        map(this.extractData),
        catchError(this.handleError)
      );

    }

    private headerSet(sourceHeader: HttpHeaders, changeKey: string, changeValue: any){
      let retHeader=new HttpHeaders();
      sourceHeader.keys().forEach( (key) => {
        if ( key !== changeKey){
          retHeader=retHeader.append(key,sourceHeader.get(key));
        } else {
        }
      });
      if ( changeValue !== undefined && changeValue !== null && changeValue !== '') {
        retHeader=retHeader.append(changeKey,changeValue);
//        console.log('Appending Key');
      }
//      console.log('Returning:');
//      console.log(retHeader);
      return retHeader;
    }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `error message was: ${error.error.error.message}`);
    }
    return throwError('Something bad happened; please try again later.');
  }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  }
