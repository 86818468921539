import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';
import { GlobalDataService } from './global-data.service';
import { TranslateService } from '@ngx-translate/core';

//import { Storage } from '@ionic/storage-angular';

//import { ChatConversationPageModule } from '../pages/chat/chat-conversation/chat-conversation.module';

@Injectable()
export class LanguageProvider {
//  userLogged: any;
  headers: HttpHeaders;
  url: string;

  constructor(
    public http: HttpClient,
    private gds: GlobalDataService,
    public translate: TranslateService
//    private localStorage: Storage,
    )
    {
      this.headers = new HttpHeaders();
      this.headers = this.headers.set('Content-Type', 'application/json');
      //this.headers = this.headers.set('X-DreamFactory-API-Key', environment.XDreamFactoryAPIKey);
      this.gds.getAgentId().subscribe(
        (data: any) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Agent Id as data is undefined');
          }
          else {
            console.log('Setting X-Exme-Agent-Id value');
            console.log(data);
            this.headers = this.headerSet(this.headers,'X-Exme-Agent-Id', data.toString());
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );

      this.gds.getSessionToken().subscribe(
        (data: string) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Session Token as data is undefined');
          }
          else {
            console.log('X-ExME-Session-Token');
            this.headers = this.headerSet(this.headers,'X-ExME-Session-Token', data);
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );

//      this.userLogged = localStorage.get('userSession');

    }

  // MIGRATED TO PUBLIC
  getAppLanguagesList(){
/*    const url = environment.apiUrlExme + '_table/vApplicationLanguages';
    console.log('Sending GET to URL:' + url );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );*/
    const postBody = {};
    return this.localMakeRequest('getAppLanguagesList',postBody,'ExMEPublic','getAppLanguagesList',null,false);

/*    let url = environment.apiUrl+ 'api/v2/' + 'ExMEPublic';
    console.log('Posting to URL: ' + url);
    let localHeader =  this.headers.append('X-Exme-Operation', 'getAppLanguagesList').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader);
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
  }

  // MIGRATED TO PUBLIC
  getLanguageFromId(languageId: number) {
    if (languageId===undefined) {
      console.error('Invalid language_id value (undefined).');
      return;
    }
/*    const url = environment.apiUrlExme + '_table/vApplicationLanguages?filter=language_id%3D' + languageId;
    console.log('Sending GET to URL:' + url );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
    const postBody = { languageId};
    return this.localMakeRequest('getLanguageFromId',postBody,'ExMEPublic','getLanguageFromId',null,false);
/*
    const url = environment.apiUrl+ 'api/v2/' + 'ExMEPublic';
    console.log('Posting to URL: ' + url);
    const localHeader =  this.headers.append('X-Exme-Operation', 'getLanguageFromId').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader);
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/

  }

  getLanguageFromCode (languageCode: string) {
/*    const url = environment.apiUrlExme + '_table/vApplicationLanguages?filter=language_code%3D' + languageCode;
    console.log('Sending GET to URL:' + url );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );*/
//          .map((response: Response) => response.json());
    const postBody = { languageCode};
    return this.localMakeRequest('getLanguageFromCode',postBody,'ExMEPublic','getLanguageFromCode',null,false);
/*    
    const url = environment.apiUrl+ 'api/v2/' + 'ExMEPublic';
    console.log('Posting to URL: ' + url);
    const localHeader =  this.headers.append('X-Exme-Operation', 'getLanguageFromCode').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader);
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/

  }

  // MIGRATED TO PUBLIC
  getAppLocalesList() {
/*    const url = environment.apiUrlExme + '_table/CRM_APPLICATION_LOCALE?filter=active%3D1';
    console.log('Sending GET to URL:' + url );
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
    */
    const postBody = {};
    return this.localMakeRequest('getAppLocalesList',postBody,'ExMEPublic','getAppLocalesList',null,false);
/*    
    const url = environment.apiUrl+ 'api/v2/' + 'ExMEPublic';
    console.log('Posting to URL: ' + url);
    const localHeader =  this.headers.append('X-Exme-Operation', 'getAppLocalesList').set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log('Headers');
    console.log(localHeader);
    return this.http.post(url, postBody, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
  }

  evtChangeLang(newLang: number){
    console.log(newLang);
    if (newLang === undefined || newLang == null ) {
      this.gds.getAgentFromStorage().then (
  //    localStorage.get('agent').then(
        (agent:any) => {
          console.log('Storage Returned');
          console.log(agent);
          if ( agent !== undefined && agent !== null ) {
            if (Array.isArray(agent)) {
              // IS ARRAY --> CONSIDER ELEMENT [0]
              this.gds.loadAgent(agent[0]);
            } else {
              // IS NOT ARRAY -> use value
              this.gds.loadAgent(agent);
            }
          } else {
            //this.publishAgent(null);
            console.log('no agent -- first login, or logged out');
          }
          /*
          var changeLang = agent[0].LANGUAGE_ID == 1 ? 'pt' : agent[0].LANGUAGE_ID == 2 ? 'en' : 'pt';
          changeLang = /(de|en|pt)/gi.test(changeLang) ? changeLang : 'en';
          console.log("Loading menu for language: " + changeLang);
          translate.use(changeLang.toLowerCase());
          this.reloadMenu(translate);*/
          this.getLanguageFromId(agent.LANGUAGE_ID).subscribe(
            (resp: any) => {
              var changeLang;
              console.log(resp);
              if (resp.resource === undefined || resp.resource[0] === undefined || resp.resource[0].language_code === undefined) {
                changeLang = 'en';
              } else {
                changeLang=resp.resource[0].language_code;
              }
              console.log('Using Language:' + changeLang.toLowerCase());
              this.translate.use(changeLang.toLowerCase());
            },
            (error) => {
              var changeLang;
              changeLang= 'EN';
              console.log('Using Language:' + changeLang.toLowerCase());
              this.translate.use(changeLang.toLowerCase());
            }
          );

        }
      );
    }
    else {
      /*var changeLang = newLang == 1 ? 'pt' : newLang == 2 ? 'en' : 'pt';
      changeLang = /(de|en|pt)/gi.test(changeLang) ? changeLang : 'en';
      console.log("Loading menu (as parameter) for language: " + changeLang);
      translate.use(changeLang.toLowerCase());
      this.reloadMenu(translate);*/
      this.getLanguageFromId(newLang).subscribe(
        (resp: any) => {
          console.log(resp);
          var changeLang;
          if (resp.resource === undefined || resp.resource[0] === undefined || resp.resource[0].language_code === undefined) {
            changeLang = 'en';
          } else {
            changeLang=resp.resource[0].language_code;
          }
          console.log('Using Language:' + changeLang.toLowerCase());
          console.log('current lang: ' + this.translate.currentLang);
          this.gds.publishdeviceLanguage(changeLang.toLowerCase());
          this.translate.use(changeLang.toLowerCase());
          console.log('new     lang: ' + this.translate.currentLang);
        },
        (error) => {
          var changeLang= 'EN';
          console.log('Using Language:' + changeLang.toLowerCase());
          this.translate.use(changeLang.toLowerCase());
        }
      );

    }
  };

  localMakeRequest(callFunction: string, body: any , service: string , operation: string, agent_id: string , deleteSession: boolean) {
    console.log('Post Body called from: ' + callFunction);
    console.log(JSON.stringify(body));

    const url = environment.apiUrl+ 'api/v2/' + service;
    console.log('Posting to URL: ' + url);
    // AMPM 20230629
    //let localHeader =  this.headers.append('X-Exme-Operation', operation).set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    let localHeader = this.headerSet(this.headers,'X-Exme-Operation', operation);
    console.log(localHeader);
    localHeader = this.headerSet(localHeader,'X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log(localHeader);
    if ( deleteSession ) {
      //localHeader = localHeader.delete('X-ExME-Session-Token');
      localHeader = this.headerSet(localHeader,'X-ExME-Session-Token',null);
      console.log(localHeader);
    }
    if ( agent_id != null && localHeader.get('X-Exme-Agent-Id') != agent_id ) {
      //localHeader = localHeader.delete('X-Exme-Agent-Id').set('X-Exme-Agent-Id',agent_id);
      localHeader = this.headerSet(localHeader,'X-Exme-Agent-Id',agent_id);
      console.log(localHeader);

    }
    console.log('Headers for call from ' + callFunction + ' are:');
    console.log(localHeader);
    return this.http.post(url, body, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );

  }

  private headerSet(sourceHeader: HttpHeaders, changeKey: string, changeValue: any){
    let retHeader=new HttpHeaders();
    sourceHeader.keys().forEach( (key) => {
      if ( key !== changeKey){
        retHeader=retHeader.append(key,sourceHeader.get(key));
      } else {
      }
    });
    if ( changeValue !== undefined && changeValue !== null && changeValue !== '') {
      retHeader=retHeader.append(changeKey,changeValue);
//      console.log('Appending Key');
    }
//    console.log('Returning:');
//    console.log(retHeader);
    return retHeader;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `error message was: ${error.error.error.message}`);
    }
    return throwError('Something bad happened; please try again later.');
  }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }


}
