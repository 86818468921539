import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncriptionService {

  constructor() { }

  encrypt(msg: string, pass: string) {
    console.log("entering encrypt")
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    const key = CryptoJS.PBKDF2(pass, salt, {
        keySize: 256 / 32,
        iterations: 100
      });
    const Iv = CryptoJS.lib.WordArray.random(128 / 8);
    const encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: Iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    // salt, iv will be hex 32 in length
    // append them to the ciphertext for use  in decryption
    const transitmessage = salt.toString() + Iv.toString() + encrypted.toString();
    return transitmessage;
  }

  decrypt(transitmessage: string, pass: string) {
    const salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
    const Iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32));
    const encrypted = transitmessage.substring(64);
    const key = CryptoJS.PBKDF2(pass, salt, {
      keySize: 256 / 32,
      iterations: 100
    });
    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: Iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    console.log('decrypted is:');
    console.log(decrypted);
    console.log(decrypted.toString(CryptoJS.enc.Utf8));
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
