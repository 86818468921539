import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UserCollectionsServices } from 'src/app/providers/user-collection-services';
import { Browser } from '@capacitor/browser';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-advertizing-impression',
  templateUrl: './advertizing-impression.component.html',
  styleUrls: ['./advertizing-impression.component.scss'],
})
export class AdvertizingImpressionComponent implements OnInit {
  @Input() impressionData;
  @Input() agentId: string;
  @Input() isVisible;
  @Input() advertizementImage;
  @Input() advertizementDescription;
  @Input() advertizementURL;
  @Input() advertizementURLText;
  @Input() advertizementRemainingTime;
  @Input() advertizementMinTime;
  @Input() advertizementCfgImpressId; // exme_mkt_cfg_col_impress_id
  @Input() advertizementImpressLogId; //exme_mkt_col_impress_log_id

  constructor(
    private userColectionsService: UserCollectionsServices,
    private _changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._changeDetection.detectChanges();
    //this.decreaseAdvertizementTime();
    console.log('isVisible', this.isVisible);
    setTimeout(() => {
      console.log('isVisible', this.isVisible);
      console.log('agentId', this.agentId);
      this.decreaseAdvertizementTime();
    }, 2000);
  }

  closeAdvertizement() {
    this.isVisible = false;
  }

  public openAdWithSystemBrowser(url: string) {
    console.log(
      'Logging Click for: AgentId=' +
        this.agentId +
        ' advertizementCfgImpressId=' +
        this.advertizementCfgImpressId +
        ' advertizementImpressLogId=' +
        this.advertizementImpressLogId
    );

    this.userColectionsService
      .notifyImpressionClick(
        this.agentId,
        this.advertizementCfgImpressId,
        this.advertizementImpressLogId
      )
      .subscribe((res) => {
        // Open the URL in the system browser
        Browser.open({
          url: url,
        });
      });
  }

  startTimeCounter() {
    console.log('startTimeCounter executed');
    console.log('isVisible: ' + this.isVisible);
    console.log(
      'advertizementRemainingTime: ' + this.advertizementRemainingTime
    );
    setTimeout(() => {
      this.decreaseAdvertizementTime();
    }, 1000);
  }

  decreaseAdvertizementTime() {
    console.log('Entering decreaseAdvertizementTime');
    if (!this.isVisible) {
      return;
    }
    this.advertizementRemainingTime -= 1;
    this.advertizementMinTime -= 1;
    if (this.advertizementRemainingTime > 0) {
      setTimeout(() => {
        this.decreaseAdvertizementTime();
      }, 1000);
    } else {
      this.isVisible = false;
    }

    console.log(
      'Exiting decreaseAdvertizementTime with advertizementRemainingTime=' +
        this.advertizementRemainingTime
    );
    console.log(
      'Exiting decreaseAdvertizementTime with advertizementMinTime=' +
        this.advertizementMinTime
    );
    console.log('isVisible', this.isVisible);
    this._changeDetection.detectChanges();
  }
}
