/* eslint-disable max-len */
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Platform,
  MenuController,
  AlertController,
  LoadingController,
} from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
//import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
//import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { UniqueDeviceID } from '@awesome-cordova-plugins/unique-device-id/ngx';
import OneSignal from 'onesignal-cordova-plugin';

import { Storage } from '@ionic/storage-angular';
import { v4 as uuidv4 } from 'uuid';

import { TranslateService } from '@ngx-translate/core';

import { common, environment, version } from '../environments/environment';
import { AgentsService } from './providers/crm-agent-provider';
import { EncriptionService } from './providers/encription.service';
import { GlobalDataService } from './providers/global-data.service';
import { GuestService } from './providers/guest.service';
import { AdsServicesService } from './providers/ads-services.service';
import { MessagingServicesProvider } from './providers/messaging-services';
/*
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';*/
import { ThemeService } from './providers/theme.service';
import lottie from 'lottie-web';
import { animation } from '@angular/animations';
import { SvgPreloadService } from './providers/svg-preload.service';
import { AdvertizingImpressionComponent } from './components/advertizing-impression/advertizing-impression.component';
import { AdvertizingPrizeComponent } from './components/advertizing-prize/advertizing-prize.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChildren('lottieSpash') lottieContainers: QueryList<ElementRef>;
  @ViewChild('advertizingPrize') advertizingPrize: AdvertizingPrizeComponent;
  @ViewChild('advertizingImpression')
  advertizingImpression: AdvertizingImpressionComponent;

  splashScreen: boolean = true;
  splashFaded: boolean = false;

  public selectedIndex = 0;

  agentId = '';
  displayingImpression = false;
  advertizementImage = '';
  advertizementDescription = '';
  advertizementURL = '';
  advertizementURLText = '';
  advertizementRemainingTime = 0;
  advertizementMinTime = 0;
  advertizementCfgImpressId = ''; // exme_mkt_cfg_col_impress_id
  advertizementImpressLogId = ''; //exme_mkt_col_impress_log_id

  displayingPrize = false;
  prizeImage = '';
  prizeDescription = '';
  prizeURL = '';
  prizeURLText = '';
  advertizementCfgPrizeId = ''; // exme_mkt_cfg_col_prize_id
  advertizementPrizeLogId = ''; //exme_mkt_col_prize_log_id

  userAvatar = '';
  firstName = 'X';
  lastName = 'M';
  agentScore = 0;
  evaluations = 0;
  tradeRating = 0;

  userLang = 'en';
  key: any;
  loginType: any;
  agentByDevice: any;
  agentFromStorage: any;
  bitmask: number;

  isAnonymous = false;

  frase = '';

  public appPages = [];
  onPauseSubscription: any;
  onResumeSubscription: any;

  isIOS: boolean;

  //Variable to control help square
  isSquareVisible: boolean = false;
//  public menuHelp = [];
  i = 0;
/*  currentMessage: {
    title: string;
    description: string;
    action: string;
  } | null = null;
*/
  //Showhelper variables
  isFirstLaunch: boolean = false;
  page = 'MenuPage';
  helperSelectedIndex: number;

  constructor(
    public platform: Platform,
    //    private splashScreen: SplashScreen,
    //    private statusBar: StatusBar,
    //    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    private badge: Badge,
    private router: Router,
    private globalization: Globalization,
    private ga: GoogleAnalytics,
    public localStorage: Storage,
    private agentsService: AgentsService,
    public translate: TranslateService,
    private gds: GlobalDataService,
    private uniqueDeviceID: UniqueDeviceID,
    private menu: MenuController,
    private crypto: EncriptionService,
    private guestSrvc: GuestService,
    private ads: AdsServicesService,
    public messagingServices: MessagingServicesProvider,
    private loadingCtrl: LoadingController,
    private theme: ThemeService,
    private svgPreloadService: SvgPreloadService
  ) {
    this.startObserving();
    this.gds.publishdeviceCountry('PT');

    this.appPages = [
      {
        title: 'COLLECTIONS',
        url: '/col-list/full-list',
        icon: 'albums',
        badgeValue: null,
        hide: false,
        svg: '/assets/icon/New-Icons/My-Collections.svg',
      },
      {
        title: 'ADD_COLLECTION',
        url: '/add-collection',
        icon: 'add',
        badgeValue: null,
        hide: false,
        svg: '/assets/icon/New-Icons/Add-Collection.svg',
      },
      {
        title: 'ADMIN_COLLECTION',
        url: '/col-admin',
        icon: 'paper-plane',
        badgeValue: null,
        hide: false,
        svg: '/assets/icon/New-Icons/Manage.svg',
      },
      {
        title: 'USER_MESSAGES',
        url: '/chat',
        icon: 'heart',
        badgeValue: null,
        hide: false,
        svg: '/assets/icon/New-Icons/Messages.svg',
      },
      {
        title: 'GROUPS',
        url: '/clubs',
        icon: 'archive',
        badgeValue: null,
        hide: false,
        svg: '/assets/icon/New-Icons/Groups.svg',
      },
      {
        title: 'PREFERENCES_PAGE',
        url: '/preferences',
        icon: 'trash',
        badgeValue: null,
        hide: false,
        svg: '/assets/icon/New-Icons/Preferences.svg',
      },
      {
        title: 'APP_MESSAGES',
        url: '/news',
        icon: 'warning',
        badgeValue: null,
        hide: false,
        svg: '/assets/icon/New-Icons/News.svg',
      },
      {
        title: 'QR_CODE',
        url: '/qr-code',
        icon: 'heart',
        badgeValue: null,
        hide: false,
        svg: '/assets/icon/New-Icons/QR-Code.svg',
      },
      {
        title: 'HISTORY',
        url: '/history',
        icon: 'heart',
        badgeValue: null,
        hide: false,
        svg: '/assets/icon/New-Icons/History.svg',
      },
    ];
/*
    this.menuHelp = [
      {
        title: 'Minhas Coleções',
        description:
          'Aqui podes ver as tua coleções e os cromos que já abriste.',
        action: 'Entra e descobre mais!',
      },
      {
        title: 'Adicionar coleção',
        description: 'Cria as tuas próprias coleções',
        action: 'Torna-te um parceiro!',
      },
      {
        title: 'Administrar coleções',
        description: 'Gere as tuas coleções',
        action: 'Entra e começa já!',
      },
      {
        title: 'Mensagens',
        description:
          'Aqui podes começar conversas com outros utilizadores, ver os teus pedidos de trocas e até falar com o grupo da coleção!',
        action: 'Entra no chat!',
      },
      {
        title: 'Clubes de troca',
        description:
          'Descobre vários grupos de trocas que te podem ajudar a completar as tuas coleções',
        action: 'Entra e integra-te!',
      },
      {
        title: 'Preferências',
        description: 'Controla as tuas preferências à tua maneira',
        action: 'Entra e descobre mais',
      },
      {
        title: 'Novidades ExME',
        description: 'Fica a par de todas as novidades sobre o ExME',
        action: 'Não percas nenhuma atualização!',
      },
      {
        title: 'Ler QR Code',
        description:
          'Com a leitura de um QR Code podes obter carteirinhas, criar coleções e partilhar a tua coleção',
        action: 'Experimenta!',
      },
      {
        title: 'Histórico',
        description:
          'Vê as carteirinhas que já abriste e os prémios que ganhaste',
        action: 'Entra para rever as carteirinhas que já abriste!',
      },
    ];
*/
    this.showSplash();
    this.initializeApp();

//    this.currentMessage = this.menuHelp[this.i];
  }

  async ngOnInit() {
    this.svgPreloadService
      .preloadSvg('./assets/icon/Loader.json', 'loaderSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg(
        './assets/icon/video-play-icon-light.json',
        'videoPlayLightSvg'
      )
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/video-play-icon-dark.json', 'videoPlayDarkSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/Stars.json', 'starsSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg(
        './assets/icon/video-play-icon-full-light.json',
        'videoPlayFullLightSvg'
      )
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/Buy-Album.json', 'buyAlbumSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/Pending_Payment.json', 'pendingPaymentSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/Waiting-Dispatch.json', 'waitingDispatchSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/Dispatched.json', 'dispatchedSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/Received.json', 'receivedSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/Wait.json', 'waitSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/Cancelled.json', 'CancelledSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/On-Hold.json', 'OnHoldSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/Didnt-Received.json', 'DidntReceivedSvg')
      .subscribe();
    this.svgPreloadService
      .preloadSvg('./assets/icon/Not-Available.json', 'NotAvailableSvg')
      .subscribe();

    await this.localStorage.create();
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      console.log('Going in here');
      this.selectedIndex = this.appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }

    this.theme.chooseTheme(localStorage.getItem('theme'));

    this.isIOS = this.platform.is('ios');

    /*try {
      // Initialize localStorage
      await this.localStorage.create();
      console.log('Storage initialized');
      
      // Fetch agentId asynchronously
      this.gds.getAgentId().subscribe(async (data: string) => {
        this.agentId = data;
        console.log('Fetched agentId:', this.agentId);
        console.log('Page:', this.page); // Ensure the page is passed correctly
  
        if (this.agentId) {
          // Initialize the helper visibility
          await this.manageHelper(this.page, this.agentId, 'initialize');
        }
      });
    } catch (error) {
      console.error('Error during ngOnInit initialization:', error);
    }*/
  }

  // Initialize helper visibility based on the storageKey
  /*async initializeHelper(page: string, agentId: string) {
    try {
      //const storageKey = `dontShowHelper_${agentId}_${page}`;
      const storageKey = `dontShowHelper_test_1`;
      console.log('Checking storage for key:', storageKey);
  
      // Retrieve the stored value
      const dontShowAgain = await this.localStorage.get(storageKey);
      //alert(`Stored value for key "${storageKey}":`+ dontShowAgain);
  
      // Check if the retrieved value is exactly true
      if (dontShowAgain === true) {
        console.log('Helper will not show because "Don\'t Show Again" is set.');
        this.isSquareVisible = false;
        this.isFirstLaunch = false;
      } else {
        console.log('Helper will show because "Don\'t Show Again" is not set.');
        this.isSquareVisible = true;
        this.isFirstLaunch = true;
      }
    } catch (error) {
      console.error('Error during initialization:', error);
    }
  }
  

  // Save the preference to not show the helper again
  async dontShowHelper(page: string, agentId: string) {
    try {
      const storageKey = `dontShowHelper_test_1`;
      console.log('Saving preference for key:', storageKey);
  
      // Save the "Don't Show Again" preference as a boolean (true)
      await this.localStorage.set(storageKey, true);
      console.log('Saved value for key:', storageKey);
  
      this.isSquareVisible = false; // Hide the helper after saving
    } catch (error) {
      console.error('Error saving preference:', error);
    }
  }*/

  /*async manageHelper(page: string, agentId: string, action: 'initialize' | 'save') {
      try {
        const storageKey = `dontShowHelper_${agentId}_${page}`;
        console.log(`Managing helper with key: ${storageKey}, action: ${action}`);
        
        if (action === 'initialize') {
          // Retrieve the stored value
          const dontShowAgain = await this.localStorage.get(storageKey);
          console.log(`Retrieved value for key "${storageKey}":`, dontShowAgain);
    
          // Check if the value is true
          if (dontShowAgain === true) {
            console.log('Helper will not show because "Don\'t Show Again" is set.');
            this.isSquareVisible = false;
            this.isFirstLaunch = false;
          } else {
            console.log('Helper will show because "Don\'t Show Again" is not set.');
            this.isSquareVisible = true;
            this.isFirstLaunch = true;
          }
        } else if (action === 'save') {
          // Save the "Don't Show Again" preference
          await this.localStorage.set(storageKey, true);
          console.log(`Saved preference for key "${storageKey}"`);
    
          // Hide the helper
          this.isSquareVisible = false;
        }
      } catch (error) {
        console.error(`Error during ${action} action for helper:`, error);
      }
    }*/

  // Close helper manually
  closeHelper() {
    this.isSquareVisible = false;
  }

  toggleSquare() {
    this.isSquareVisible = !this.isSquareVisible;

    if (this.isSquareVisible) {
      this.helperSelectedIndex = -1;
//      this.currentMessage = this.menuHelp[this.selectedIndex];

      this.unsetFirstLaunch();
    }
  }
  setFirstLaunch(){
    this.isFirstLaunch = true;
  }

  unsetFirstLaunch(){
    this.isFirstLaunch = false;
  }
  selectHelp(index: number) {
    this.helperSelectedIndex = index;
//    this.currentMessage = this.menuHelp[index];
  }

  ngAfterViewInit() {
    this.splashScreenLoader();
  }

  async showSplash() {
    await this.platform.ready();
    const lottie = (window as any).lottie;
    if (this.platform.is('ios')) {
      //if ( lottie!== undefined && lottie!= null && lottie.splashScreen!==undefined && lottie.splashScreen!==null){
      await lottie.splashscreen.hide();
      await lottie.splashscreen.show(
        'public/assets/icon/SpashScreen.json',
        false
      );
      lottie.splashscreen.on('lottieAnimationEnd', (ev: Event) => {
        lottie.splashscreen.hide();
      });
      //}
    }
  }

  splashScreenLoader() {
    this.lottieContainers.forEach((container) => {
      const animation = lottie.loadAnimation({
        container: container.nativeElement,
        path: './assets/icon/SpashScreen.json',
        renderer: 'svg',
        loop: false,
        autoplay: true,
      });

      const checkFrameRate = () => {
        if (animation.frameRate > 0) {
          console.log('Frame rate is valid, starting progress check');
          checkAnimationProgress();
        } else {
          console.log('Frame rate is not valid yet, checking again...');
          setTimeout(checkFrameRate, 100);
        }
      };

      const checkAnimationProgress = () => {
        const frameRate = animation.frameRate;
        const totalFrames = animation.totalFrames;

        // Check if the animation has reached the last frame
        if (animation.currentFrame >= totalFrames - 1) {
          console.log('Animation completed');
          this.startFadeOut();
        } else {
          // If not, check again after a short delay
          setTimeout(checkAnimationProgress, 1000 / frameRate);
        }
      };

      // Start checking the frame rate
      checkFrameRate();
    });
  }

  startFadeOut() {
    this.splashFaded = true; // Trigger the fade-out animation
    setTimeout(() => {
      this.splashScreen = false; // Hide the splash screen after the animation completes
    }, 1000); // Match this delay with the duration of the CSS animation
  }

  logout(clear: boolean) {
    this.gds.logout(clear);
  }

  getUserKeyPassFromStorage(iteration: number) {
    this.gds.getUserKeyPassFromStorage().then(
      (key) => {
        this.bitmask += 1;
        console.log('APP COMP INIT- Got getUserKeyPassFromStorage=' + key);
        this.key = key;
      },
      (err) => {
        if (iteration < 6) {
          setTimeout(() => {
            this.getUserKeyPassFromStorage(iteration + 1);
          }, 100);
        } else {
          this.bitmask += 1;
        }
      }
    );
  }

  getUserLoginTypeFromStorage(iteration: number) {
    this.gds.getUserLoginTypeFromStorage().then(
      (tp) => {
        this.bitmask += 2;
        console.log('APP COMP INIT- Got getUserLoginTypeFromStorage=' + tp);
        this.loginType = tp;
      },
      (err) => {
        if (iteration < 6) {
          setTimeout(() => {
            this.getUserLoginTypeFromStorage(iteration + 1);
          }, 100);
        } else {
          this.bitmask += 2;
        }
      }
    );
  }

  async initializeAdMob() {
    await this.ads.initializeAdMob();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      console.log(this.platform.platforms());
      if (version.buildPlatform === 'cordova') {
        StatusBar.setOverlaysWebView({ overlay: false });
        StatusBar.setStyle({ style: Style.Light });
        StatusBar.show();
      }
      //SplashScreen.hide();
      this.initializeAdMob();
      //this.ads.evtShowBannerAdd();
      if (version.buildPlatform === 'cordova') {
        this.OneSignalInit();
      }
      if (this.platform.is('ios')) {
        //this.requestPermission();
      }

      console.log('subscribing Prize');
      this.gds.getExmePrize().subscribe(
        (exmePrize: any) => {
          console.log('retrieved exme prize data');
          if (exmePrize !== null) {
            console.log('retrieved exme impression data');
            console.log(exmePrize);
            this.prizeImage = exmePrize.prize_image;
            this.prizeDescription = exmePrize.prize_text;
            this.prizeURL = exmePrize.prize_url;
            this.prizeURLText = exmePrize.prize_url_text;
            this.advertizementCfgPrizeId = exmePrize.exme_mkt_cfg_col_prizes_id;
            this.advertizementPrizeLogId = exmePrize.exme_mkt_col_prize_log_id;
            this.displayingPrize = true;
            //this._changeDetection.detectChanges();
            //setTimeout(() => { this.decreaseAdvertizementTime(); },1000);
          }
        },
        (error) => {}
      );
      console.log('subscribing Impressions');
      this.gds.getExmeImpression().subscribe(
        (exmeImpression) => {
          console.log('retrieved exme impression data');
          console.log(exmeImpression);
          if (exmeImpression !== null) {
            this.advertizingImpression.advertizementImage =
              exmeImpression.advertizing_image;
            this.advertizingImpression.advertizementDescription =
              exmeImpression.advertizing_text;
            this.advertizingImpression.advertizementURL =
              exmeImpression.advertizing_url;
            this.advertizingImpression.advertizementURLText =
              exmeImpression.advertizing_url_text;
            this.advertizingImpression.advertizementRemainingTime =
              exmeImpression.max_seconds;
            this.advertizingImpression.advertizementMinTime =
              exmeImpression.min_seconds;
            this.advertizingImpression.advertizementCfgImpressId =
              exmeImpression.exme_mkt_cfg_col_impress_id;
            this.advertizingImpression.advertizementImpressLogId =
              exmeImpression.exme_mkt_col_impress_log_id;
            this.advertizingImpression.isVisible = true;
            this.advertizementImage = exmeImpression.advertizing_image;
            this.advertizementDescription = exmeImpression.advertizing_text;
            this.advertizementURL = exmeImpression.advertizing_url;
            this.advertizementURLText = exmeImpression.advertizing_url_text;
            this.advertizementRemainingTime = exmeImpression.max_seconds;
            this.advertizementMinTime = exmeImpression.min_seconds;
            this.advertizementCfgImpressId =
              exmeImpression.exme_mkt_cfg_col_impress_id;
            this.advertizementImpressLogId =
              exmeImpression.exme_mkt_col_impress_log_id;
            this.displayingImpression = true;
            //this._changeDetection.detectChanges();
            //setTimeout(() => { this.decreaseAdvertizementTime(); },1000);
            this.advertizingImpression.startTimeCounter();
          }
        },
        (error) => {}
      );

      this.loadDeviceId();
      this.getLocales();
      this.gds.getAgentFromStorage().then(
        (agent: any) => {
          console.log('Storage Returned');
          console.log(agent);
          if (agent !== undefined && agent !== null) {
            if (Array.isArray(agent)) {
              // IS ARRAY --> CONSIDER ELEMENT [0]
              this.gds.loadAgent(agent[0]);
            } else {
              // IS NOT ARRAY -> use value
              this.gds.loadAgent(agent);
            }
          } else {
            //this.publishAgent(null);
            console.log('no agent -- first login, or logged out');
            this.router.navigate(['/login']);
          }
          if (
            agent !== undefined &&
            agent !== null &&
            agent.AGENT_ID !== undefined &&
            agent.AGENT_ID !== null
            //&& agent !== {}
          ) {
            console.log('Writing');
            this.gds.publishAgent(agent);
            this.gds.publishAgentId(agent.AGENT_ID);
            this.gds.publishAgentEmail(agent.EMAIL);
            this.gds.publishAgentLanguage(agent.LANGUAGE_ID);
          } else {
            console.log('Unexpected Agent from storage is:');
            console.log(agent);
            this.gds.publishAgentLanguage(1);
            this.router.navigate(['/login']);
          }
        },
        (err) => {
          console.error('Error on getAgentFromStorage');
          console.error(err);
          this.router.navigate(['/login']);
        }
      );
      console.log('Init Step 1');
      this.bitmask = 0;
      setTimeout(() => {
        this.getUserKeyPassFromStorage(1);
      }, 50);
      setTimeout(() => {
        this.getUserLoginTypeFromStorage(1);
      }, 70);
      this.gds.getdeviceId().subscribe(
        (uuid) => {
          this.bitmask += 4;
          console.log('APP COMP INIT- Got uniqueDeviceID=' + uuid);
          this.guestSrvc
            .getCrmGuestAgentByDeviceId(uuid)
            .subscribe((agt: any) => {
              this.bitmask += 8;
              console.log('Got getCrmAgentByDeviceId=' + agt);
              this.agentByDevice = agt.resource[0];
            });
        },
        (error) => {
          if (
            (error.status !== undefined &&
              error.status !== null &&
              error.status === 401) ||
            (error.status_code !== undefined &&
              error.status_code !== null &&
              error.status_code === 401)
          ) {
            this.gds.logout(false);
          }
        }
      );
      this.onPauseSubscription = this.platform.pause.subscribe(() => {
        // do stuff
      });

      this.onResumeSubscription = this.platform.resume.subscribe(async () => {
        let agentId = '';
        let token = '';
        console.log('### 20230606 ###  - resuming platform');
        const loading = await this.loadingCtrl.create({
          showBackdrop: true,
          spinner: 'bubbles',
        });
        loading.present();
        this.gds.getAgentId().subscribe((data) => {
          agentId = data;
        });
        this.gds.getSessionToken().subscribe((localToken: string) => {
          token = localToken;
        });

        setTimeout(() => {
          if (agentId === '' || agentId === null) {
            this.router.navigate(['/col-list']);
            loading.dismiss();
          } else {
            loading.dismiss();
          }
          if (token === '' || token === null) {
            this.router.navigate(['/col-list']);
            loading.dismiss();
          } else {
            loading.dismiss();
          }
        }, 500);
        console.log('### 20230606 ###  - resumed platform');
      });
      /*
      this.gds.getAgentFromStorage().then( (agt) => {
        this.bitmask+=16;
        console.log("APP COMP INIT- Got getAgentFromStorage="+agt);
        this.agentFromStorage = agt
      });
      */

      //      setTimeout(()=>{this.testData(1)},100);
      /*
        this.gds.getUserKeyPassFromStorage().then(
          (key) => {
            console.log("Got Key!")
            if (key !== null) {
              console.log('ok temos valores de login:');
              console.log(key);
              key.password = this.crypto.decrypt(key.password, common.encryptKey);
              console.log(key);
              if ( key.email !== null && key.email !== '' && key.email !== undefined ) {
                this.getUserSession(key);
              }
            }
            else {
              console.log("No login data")
              this.gds.getUserLoginTypeFromStorage().then(
                (tp) => {
                  console.log("User Login Type = " + tp)
                  if (tp === 'guestUser' || tp === 'trialUser') {
                    this.gds.getAgentFromStorage().then(
                      (agent:any) => {
                        if (agent === null || agent === {} ) {
                          if (tp == 'guestUser') {
                            this.uniqueDeviceID.get().then(
                              (uuid) => {
                                this.guestSrvc.getCrmAgentByDeviceId(uuid).subscribe(
                                  (agt: any) => {
                                    console.log("Loading Agent data from Device Id")
                                    this.gds.publishAgent(agt);
                                    this.gds.publishAgentId(agt.AGENT_ID);
                                    this.gds.publishAgentEmail(agt.EMAIL)
                                    this.gds.publishAgentLanguage(agt.LANGUAGE_ID);
                                  }
                                )
                              }
                            )
                          }
                        } else {
                            this.gds.publishAgentId(agent.AGENT_ID);
                            this.gds.publishAgentEmail(agent.EMAIL);
                            this.gds.publishAgentLanguage(agent.LANGUAGE_ID);
                        }
                      }
                    ).finally(() => {
                      this.router.navigate(['/col-list']);
                    });

                  }
                  else {
                    console.log('no data for login available on storage');
                    this.router.navigate(['/login']);
                  }
                }
              )
            }
          },
          (error) => {
            this.router.navigate(['/login']);
          }
        );

*/
      console.log('Init Step Out');
    });
  }

  /*  testData(iteration: number) {
    console.log('AMPM in testData');
    console.log(this.bitmask);
    if (this.bitmask !== 31 && iteration < 20 ) {
      setTimeout(() => {
        this.testData(iteration + 1);
      }, 100);
      return;
    }
    if ( this.bitmask !== 31) {
      // no data
      console.log('Navigating to Login from testData func');
      this.router.navigate(['/login']);
    }

    if (this.key !== null && this.key !== undefined) {
      console.log('ok temos valores de login:');
      console.log(this.key);
      this.key.password = this.crypto.decrypt(this.key.password, common.encryptKey);
      console.log(this.key.password);
      if ( this.key.email !== null && this.key.email !== '' && this.key.email !== undefined ) {
        this.getUserSession(this.key);
        // this.getUserSession enters col-list
        return;
      }
    }

      console.log('User Login Type = ' + this.loginType);
      if (this.loginType === 'guestUser' || this.loginType === 'trialUser') {
            if (this.agentFromStorage === undefined || this.agentFromStorage === null
              //|| this.agentFromStorage === {}
              ) {
              console.log('publishing data');
              console.log(this.agentByDevice);
              this.gds.publishAgent(this.agentByDevice);
              this.gds.publishAgentId(this.agentByDevice.AGENT_ID);
              this.gds.publishAgentEmail(this.agentByDevice.EMAIL);
              this.gds.publishAgentLanguage(this.agentByDevice.LANGUAGE_ID);
              console.log('Navigating to col-list from guestUser');
              this.router.navigate(['/col-list']);
            } else {
              console.log('Must Get Trial or Guest Agent');
              this.router.navigate(['/login']);
            }
      }
      else {
        console.log('Navigating to login from guestUser as loginType not defined');
        this.router.navigate(['/login']);
      }

  }
*/
  async loadDeviceId() {
    const uid = await this.gds.getdeviceIdFromStorage();

    if (uid === null) {
      this.uniqueDeviceID
        .get()
        .then((uuid: any) => {
          console.log('OBTIDO DEVICE ID - CORDOVA');
          this.gds.publishDeviceId(uuid);
        })
        .catch((error: any) => {
          console.log(error);
          console.log('OBTER DEVICE ID de NPM UUID');
          this.gds.publishDeviceId(uuidv4());
        });
    } else {
      console.log('OBTIDO DEVICE ID - DA STORAGE');
      this.gds.publishDeviceId(uid);
    }
  }

  getLocales() {
    this.translate.setDefaultLang('en');

    this.globalization
      .getPreferredLanguage()
      .then((res) => {
        console.log('Globalization User Lang -> ' + res.value);
        this.gds.publishdeviceLanguage(res.value);
        this.userLang = /(de|en|pt)/gi.test(res.value) ? res.value : 'en';
        this.translate.use(this.userLang.toLowerCase());
      })
      .catch((e) => {
        console.log(
          'Not possible to get Language - getting from browser default'
        );
        const lang = navigator.language.split('-')[0];
        console.log('Navigator language -> ' + lang);
        this.gds.publishdeviceLanguage(lang);
        this.userLang = /(de|en|pt)/gi.test(lang) ? lang : 'en';
      });

    this.globalization
      .getLocaleName()
      .then((res) => {
        console.log('Globalization Locale -> ' + res.value);
        this.gds.publishdeviceCountry(res.value);
      })
      .catch((e) => {
        console.log(
          'Not possible to get Locale - getting from browser default'
        );
        const locale = navigator.language.split('-')[1];
        console.log('Navigator language -> ' + locale);
        this.gds.publishdeviceCountry(locale);
      });
  }

  startObserving() {
    console.log('-------------OBSERVING GLOBAL VARS--------------------');
    /* Teste do serviço para obter vars globais */
    this.gds.getObservable().subscribe((data: any) => {
      console.log('Data received Observable', data);
      if (
        data.refreshBadgesMenu !== undefined &&
        data.refreshAgentId !== undefined &&
        data.refreshAgentId !== null &&
        data.refreshAgentId !== ''
      ) {
        console.log(
          'Refreshing Badge Numbers on menu for agentId ' + data.refreshAgentId
        );
        this.agentsService
          .getCrmAgentPendingActions(data.refreshAgentId)
          .subscribe(
            async (pendActions: any) => {
              if (
                !(
                  pendActions == null ||
                  pendActions.resource === undefined ||
                  pendActions.resource[0] == null
                )
              ) {
                const pendingActions = pendActions.resource[0];
                console.log('pendingActions');
                console.log(pendingActions);
                this.appPages[0].badgeValue =
                  pendingActions.numPendingTradeRequest === 0
                    ? null
                    : pendingActions.numPendingTradeRequest;
                this.appPages[1].badgeValue = null;
                if (pendingActions.numPendingDelegations === 0) {
                  this.appPages[2].badgeValue = null;
                } else {
                  this.appPages[2].badgeValue =
                    pendingActions.numPendingDelegations;
                  this.appPages[2].hide = false;
                }
                //            this.appPages[2].badgeValue = (pendingActions.numPendingDelegations==0?null:pendingActions.numPendingDelegations);
                this.appPages[4].badgeValue =
                  pendingActions.numGroupsPending === 0
                    ? null
                    : pendingActions.numGroupsPending;
                console.log(
                  'setting group value to ' + this.appPages[4].badgeValue
                );
                this.appPages[5].badgeValue = null;
                this.appPages[3].badgeValue =
                  pendingActions.user_messages_pending === 0
                    ? null
                    : pendingActions.user_messages_pending;
                this.appPages[6].badgeValue = pendingActions.messages_pending
                  ? '1'
                  : null;
                const locTotalBadges =
                  pendingActions.numPendingTradeRequest +
                  pendingActions.numPendingDelegations +
                  pendingActions.numGroupsPending +
                  pendingActions.user_messages_pending +
                  pendingActions.messages_pending;
                this.badge.set(locTotalBadges);
                console.log('Menu Badges Updated to:' + locTotalBadges);
                if (
                  pendingActions.terms_accept_version !==
                  pendingActions.current_terms_version
                ) {
                  const alert = await this.alertCtrl.create({
                    header: this.translate.instant('TERMS_CHANGED_TITLE'),
                    subHeader: this.translate.instant('TERMS_CHANGED_SUBTITLE'),
                    buttons: [
                      {
                        text: this.translate.instant('ACCEPT_NEW_TERMS'),
                        handler: (data2) => {
                          console.log('New Terms Accepted');
                          this.agentsService
                            .acceptNewTerms(
                              pendingActions.agent_id,
                              pendingActions.current_terms_version
                            )
                            .subscribe(
                              () => {
                                console.log('Success Updating Terms');
                              },
                              (error) => {
                                console.log('Error Updating Terms');
                                console.log(error);
                              }
                            );
                        },
                      },
                      {
                        text: this.translate.instant('CHECK_NEW_TERMS'),
                        handler: (data3) => {
                          console.log('Checking New Terms');
                          this.router.navigateByUrl(
                            '/preferences/terms-of-use/en'
                          );
                        },
                      },
                    ],
                    cssClass: 'alert-buttons',
                  });
                  alert.present();
                }
              }
            },
            (error) => {
              console.log('error is:');
              console.log(error);
              console.log(error.status);
              console.log(error.status_code);
              console.log(error.error);
              if (
                error.status !== undefined &&
                error.status != null &&
                error.status === 401
              ) {
                console.log('In if...');
                this.gds.logout(false);
              }
              this.appPages[0].badgeValue = null;
              this.appPages[1].badgeValue = null;
              this.appPages[2].badgeValue = null;
              this.appPages[3].badgeValue = null;
              this.appPages[4].badgeValue = null;
              this.appPages[5].badgeValue = null;
              this.appPages[6].badgeValue = null;
              //          this.appPages[5].badgeValue = null;
              this.badge.clear();
            }
          );
      } else {
        console.log('Observable Data is undefined');
      }
    });

    this.gds.getuserAvatar().subscribe((data) => {
      this.userAvatar = data;
      console.log('APP COMPONENT GET USER AVATAR RETURNED:' + data);
    });

    this.gds.getUserTradeRating().subscribe((data) => {
      this.tradeRating = data;
      console.log('APP COMPONENT GET USER TRADE RATING RETURNED:' + data);
    });

    this.gds.getNumEvaluations().subscribe((data) => {
      this.evaluations = data;
    });

    this.gds.getdeviceLanguage().subscribe((data: any) => {
      console.log('Reload do Menu na lingua certa....');
      console.log('loading menu for: ' + data);
      if (data.lang !== undefined) {
        this.translate.use(data.lang.toLowerCase());
      } else {
        if (data !== undefined && data != null) {
          this.translate.use(data.toLowerCase());
        } else {
          console.log('Não Temos ainda lingua definida....');
        }
      }
    });

    this.gds.getAgentFromStorage().then((agent: any) => {
      console.log('Storage Returned');
      console.log(agent);
      if (agent !== undefined && agent !== null) {
        if (Array.isArray(agent)) {
          // IS ARRAY --> CONSIDER ELEMENT [0]
          this.gds.loadAgent(agent[0]);
        } else {
          // IS NOT ARRAY -> use value
          this.gds.loadAgent(agent);
        }
      } else {
        //this.publishAgent(null);
        console.log('no agent -- first login, or logged out');
      }
    });

    this.gds.getdeviceCountry().subscribe((data) => {
      console.log('Device Country OBSERVABLE', data);
    });

    this.gds.getdeviceLanguage().subscribe((data) => {
      console.log('Device Language OBSERVABLE', data);
    });

    this.gds.getdeviceId().subscribe((data) => {
      console.log('Device Id OBSERVABLE', data);
    });

    this.gds.getAgentId().subscribe((data) => {
      console.log('Agent Id  OBSERVABLE', data);
      this.agentId = data;
    });

    this.gds.getagentEmail().subscribe((data) => {
      console.log('Agent Email OBSERVABLE', data);
      if (data === 'ExMEAnonymousAccount@exme.club') {
        this.isAnonymous = true;
      } else {
        this.isAnonymous = false;
      }
    });

    this.gds.getBadges().subscribe((data) => {
      for (const i of [0, 1, 2, 3, 4, 5, 6]) {
        if (this.appPages[i] !== undefined) {
          this.appPages[i].badgeValue = data[i];
        }
      }
    });
  }

  /* OLD OneSignal Code
  initializeOneSignal() {
    this.oneSignal.startInit('aea12ae3-de12-44cb-9f0e-119ba39fcb82', '572454532437');
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
    this.oneSignal.handleNotificationReceived().subscribe(() => {
      // do something when notification is received
      this.badge.increase(1);
    });
    this.oneSignal.handleNotificationOpened().subscribe(() => {
      // do something when a notification is opened
    });
    this.oneSignal.endInit();
  }
  */

  // Call this function when your app starts
  OneSignalInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);
    if (this.platform.is('cordova')) {
      // NOTE: Update the setAppId value below with your OneSignal AppId.
      OneSignal.setAppId('aea12ae3-de12-44cb-9f0e-119ba39fcb82');
      OneSignal.setNotificationOpenedHandler(function (jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
        this.badge.increase(1);
      });

      // Prompts the user for notification permissions.
      //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
      OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
        console.log('User accepted notifications: ' + accepted);
      });
    }
  }

  initializeGoogleAnalytics() {
    this.ga
      .startTrackerWithId(common.gaProperty)
      .then(() => {
        console.log('Google analytics is ready now');
        this.ga.trackView('application_initialized');
      })
      .catch((e) => console.log('Error starting GoogleAnalytics', e));
  }

  getUserSession(sessionCredentials: any) {
    this.agentsService.getUserSession(sessionCredentials).subscribe(
      (session) => {
        console.log('-------Step 2--------');
        console.log(session);
        if (this.platform.is('cordova')) {
          this.ga
            .trackEvent('login_page', 'login', 'success', 1)
            .then(() => {});
        }
        this.gds.publishUserSession(session);
        // ver se é suficiente ou se precisamos voltar a "carregar" o agente na storage
        console.log('Navigating to col-list on function getUserSession');
        this.router.navigate(['/col-list']);
      },
      (err) => {
        console.log('Navigating to login on function getUserSession');
        this.router.navigate(['/login']);
      }
    );
  }
  /*
  public async getStatus(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.getStatus();

    console.log(response);
    // { status: 'authorized' } for example

    return response;
  }

  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();

    console.log(response);
    // { status: 'authorized' } for example

    return response;
  }
*/

  preventDefault(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  log(string: any) {
    console.log(string);
  }
}
