import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
//import { Admob, AdmobOriginal, AdmobOptions } from '@awesome-cordova-plugins/admob';
//import { AdMob, BannerAd } from '@admob-plus/ionic/ngx';
import {  BannerAdOptions, BannerAdSize, BannerAdPosition, BannerAdPluginEvents,
          AdMobBannerSize, AdOptions, AdLoadInfo, InterstitialAdPluginEvents } from '@capacitor-community/admob';
import { environment, version } from '../../environments/environment';
import { AgentsService } from './crm-agent-provider';
//import { AdMob, AdmobConsentStatus, AdmobConsentDebugGeography } from '@capacitor-community/admob';
import { AdMob } from '@capacitor-community/admob';


@Injectable()
export class AdsServicesService {

  adInterstitialId = 'ca-app-pub-8915683900096700/1639403438';
  adUnitId = 'ca-app-pub-8915683900096700/2788833579'; //DEMO ID TEST IOS

  interstitialOptions: AdOptions = {
    adId: this.adInterstitialId,
    //isTesting: true
    // npa: true
  };

  bannerOptions: BannerAdOptions = {
    adId: this.adUnitId,
    adSize: BannerAdSize.ADAPTIVE_BANNER,
    position: BannerAdPosition.BOTTOM_CENTER,
    margin: 0,
    //isTesting: true
    // npa: true
  };

  bannerIsHide: boolean;
  private addsActive: boolean;
  private addsBannerInterval: number;
  private addsInterstitialInterval: number;
  private addsConfigurationInterval: number;
  private addsLastBanner: number;
  private addsLastInterstitial: number;
  private addsLastConfigurationRetrieval: number;
  private appMargin = 0;


  constructor(
    //private admob: AdMob,
              private agentsService: AgentsService,
              private platform: Platform)
    {
      /*
      this.platform.ready().then(async () => {
        await this.admob.start();
      });
      */

    }


    /*
  async showConsent() {
      const consentInfo = await AdMob.requestConsentInfo();

      if (consentInfo.isConsentFormAvailable && consentInfo.status === AdmobConsentStatus.REQUIRED) {
        const {status} = await AdMob.showConsentForm();
      }
    }
      */


  async initializeAdMob(): Promise<void> {

    //const { status } = await AdMob.trackingAuthorizationStatus();
    //console.log('TP 20240225 - trackingAuthorizationStatus status : ',status);

    if (status === 'notDetermined') {
      /**
       * If you want to explain TrackingAuthorization before showing the iOS dialog,
       * you can show the modal here.
       * ex)
       * const modal = await this.modalCtrl.create({
       *   component: RequestTrackingPage,
       * });
       * await modal.present();
       * await modal.onDidDismiss();  // Wait for close modal
       **/
    }




/*     AdMob.initialize({

      requestTrackingAuthorization: true,
//      testingDevices: ['2077ef9a63d2b398840261c8221a0c9b'],
      initializeForTesting: true,
    }); */


     AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
      // Subscribe Banner Event Listener
      console.log('TP 20240225 - Subscribe Banner Event Listener');
    });

    AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => {
      // Subscribe Change Banner Size
      console.log('TP 20240225 - Subscribe Change Banner Size');

      this.appMargin = size.height;
      if (this.appMargin > 0) {
        const app: HTMLElement = document.querySelector('ion-router-outlet');
        console.log('TP 20240225 HTMLElement', JSON.stringify(app));
        console.log('TP 20240225 marginBottom changed from : ' + app.style.marginBottom + ' to ' + this.appMargin);
        app.style.marginBottom = this.appMargin + 'px';


      }
    });


  }



  showBannerAdForce() {
      if (this.platform.is('cordova')) {
        if (this.addsActive !== false) {
          this.internalShowBannerAdNew();
        }
      }
  }

  evtShowInterstitialAd() {
    this.getApplicationAddsConfiguration();
    if (this.addsActive === false) {
      console.log('skipping Adds: inactive');
      this.hideBannerAd();
      return;
    }
    if ( this.addsLastInterstitial == null || Date.now() > this.addsLastInterstitial + this.addsInterstitialInterval) {
      console.log('addsGetting Interstitial');
      this.addsLastInterstitial = Date.now();
      console.log('addsLastInterstitial:' + this.addsLastInterstitial);
      if (this.platform.is('cordova')) {
        console.log('Getting Interstitial');
        this.internalShowInterstitialAdNew();
      }
    } else {
      console.log('Skipping Interstitial');
    }
  };


  getApplicationAddsConfiguration() {
    if (this.addsLastConfigurationRetrieval == null || Date.now() > this.addsLastConfigurationRetrieval + this.addsConfigurationInterval){
      console.log('TP 20240225 getting Adds Configuration');
      this.addsLastConfigurationRetrieval= Date.now();
      this.agentsService.getApplicationAddsConfiguration().subscribe(
        (response: any) => {
          console.log(response);
          this.addsActive = response.resource[0].addsActive;
          this.addsBannerInterval = response.resource[0].addsBannerInterval;
          this.addsInterstitialInterval= response.resource[0].addsInterstitialInterval;
          this.addsConfigurationInterval = response.resource[0].addsConfigurationInterval;
          console.log('addsActive='+this.addsActive);
          console.log('addsBannerInterval='+this.addsBannerInterval);
          console.log('addsInterstitialInterval='+this.addsInterstitialInterval);
          console.log('addsConfigurationInterval='+this.addsConfigurationInterval);
          if ( this.addsActive === false ) {
            console.log('TP 20240225 calling hideBannerAd');
            this.hideBannerAd();
          }
        },
        (error) => {
          console.log('TP 20240225 error hideBannerAd',  JSON.stringify(error));
          this.addsActive = false;
          console.log('TP 20240225 calling hideBannerAd');
          this.hideBannerAd();
          this.addsBannerInterval = 1000000;
          this.addsInterstitialInterval= 1000000;
          this.addsConfigurationInterval = 60000;
          console.log('addsActive='+this.addsActive);
          console.log('addsBannerInterval='+this.addsBannerInterval);
          console.log('addsInterstitialInterval='+this.addsInterstitialInterval);
          console.log('addsConfigurationInterval='+this.addsConfigurationInterval);
        }
      );
     }
    else{

      console.log('TP 20240225 still not time to get adds configuration');
      console.log('addsActive='+this.addsActive);
      console.log('addsLastConfigurationRetrieval', this.addsLastConfigurationRetrieval);
      console.log('addsBannerInterval='+this.addsBannerInterval);
      console.log('addsInterstitialInterval='+this.addsInterstitialInterval);
      console.log('addsConfigurationInterval='+this.addsConfigurationInterval);
    }
  }

  evtShowInterstitialAdd(collectionType: string) {
    if (environment.production && version.buildPlatform.toLowerCase() !== 'browser') {
      if (collectionType==='V') {
        console.log('Skipping for Virtual Collection');
        this.hideBannerAd();
      } else {
        this.evtShowInterstitialAd();
      }
    }
    else {
      console.log('Skipping Interstitial as we are not in production or browser. Collection type is:' + collectionType);
    }
    //this.publishSomeData({showInterstitialAd: 1});
  }




  evtShowBannerAdd(collectionType: string) {
    console.log(version.buildPlatform);
    if (environment.production && version.buildPlatform.toLowerCase() !== 'browser') {
      if (collectionType==='V') {
        console.log('TP 20240225 collectionType = ', collectionType);
        console.log('TP 20240225 Skipping for Virtual Collection');
        this.hideBannerAd();
      } else {
        console.log('TP 20240225 addsActive = ', this.addsActive);
        console.log('TP 20240225 collectionType = ', collectionType);
        console.log('TP 20240225 Showing for traditional Collection');
        this.evtShowBannerAd();
      }
    }
    else {
      console.log('TP 20240225 Skipping Banner as we are not in production or browser');
    }
    //this.publishSomeData({showBannerAd: 1});
  }



  public evtShowBannerAd() {
    this.getApplicationAddsConfiguration();
    if (this.addsActive === false) {
      console.log('TP 20240225 skipping Adds: inactive');
      this.hideBannerAd();
      return;
    }
    console.log('TP 20240225 addsCurrentDate:' + Date.now());
    console.log('TP 20240225 addsLastBanner:' + this.addsLastBanner);

    if ( this.addsLastBanner == null || Date.now() > this.addsLastBanner + this.addsBannerInterval) {
      console.log('TP 20240225 addsGetting Banner');
      this.addsLastBanner = Date.now();
      console.log('TP 20240225 addsLastBanner:' + this.addsLastBanner);
      if (this.platform.is('cordova')) {
        console.log('TP 20240225 is cordova');
        console.log('TP 20240225 calling internalShowBannerAdNew');

        const result = this.internalShowBannerAdNew().catch(e =>
          console.error('TP 20240225 error on internalShowBannerAdNew: ', JSON.stringify(e)),
        );

        if (result === undefined) {
          console.log('TP 20240225 internalShowBannerAdNew result is : ',JSON.stringify(result));
          return;
        }
        console.log('TP 20240225 internalShowBannerAdNew result is : ',JSON.stringify(result));
      }
    }
    else {
      console.log('TP 20240225 Skipping Banner');
    }
  }

  async hideBannerAd() {
    //this.addsLastBanner = null;
    const app: HTMLElement = document.querySelector('ion-router-outlet');
    const result = await AdMob.hideBanner()
      .catch(e => console.log('TP 20240225 error on hideBanner: ', e));
      console.log('TP 20240225 hideBannerAd result is : ',result);
    if (result === undefined) {
      app.style.marginBottom = '0px';
      console.log('TP 20240225 hiding admob banner - marginBottom = 0px ');
      this.bannerIsHide = true;
      return;
    }
    if ( this.platform.is('ios') ) {
      app.style.marginBottom = '0px';
      console.log('AC 20240306 hiding admob banner - marginBottom = 0px ');
      this.bannerIsHide = true;
      return;
    }

  }




  evtShowRewardVideoAdd(rewardFunction) {
    console.log('TODO: Implement');
    return;
    if (environment.production && version.buildPlatform.toLowerCase() !== 'browser') {
      this.evtShowBannerAd();
    }
    else {
      console.log('Skipping Bann as we are not in production or browser');
    }

  }



  private async internalShowBannerAdNew() {
    console.log('TP 20240225 - entering on internalShowBannerAdNew');

    if (this.platform.is('ios')) {
      this.adUnitId = 'ca-app-pub-8915683900096700/2788833579'; //IOS EXME AD ID
      //adUnitId = 'ca-app-pub-3940256099942544/2934735716'; //DEMO ID TEST IOS
    }
    if (this.platform.is('android')) {
      this.adUnitId  = 'ca-app-pub-8915683900096700/4732293020'; //ANDROID EXME AD ID
      //adUnitId = 'ca-app-pub-3940256099942544/6300978111'; //DEMO ID TEST ANDROID
    }


/*     if (this.bannerIsHide) {
      console.log('TP 20240225 - bannerIsHide is ', this.bannerIsHide);
      console.log('TP 20240225 - Calling resumeBanner');
      AdMob.resumeBanner();
      this.bannerIsHide = false;
    } else {

    console.log('TP 20240225 - calling showBanner');
    const result = AdMob.showBanner(this.bannerOptions).catch(e =>
    console.error('TP 20240225 error on showBanner: ', JSON.stringify(e)),
    );

    if (result === undefined) {
      console.log('TP 20240225 result is : ',JSON.stringify(result));

      return;
    }

  } */
  }



  private async internalShowInterstitialAdNew() {

    if (this.platform.is('ios')) {
      //adIntestitialId = 'ca-app-pub-3940256099942544/4411468910'; //DEMO ID TEST IOS
      this.adInterstitialId = 'ca-app-pub-8915683900096700/1639403438'; //IOS EXME AD ID
    }
    if (this.platform.is('android')) {
      //adIntestitialId = 'ca-app-pub-3940256099942544/1033173712'; //DEMO ID TEST ANDROID
      this.adInterstitialId = 'ca-app-pub-8915683900096700/3802532349'; //ANDROID EXME AD ID
    }

    AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
      // Subscribe prepared interstitial
    });


    await AdMob.prepareInterstitial(this.interstitialOptions);
    await AdMob.showInterstitial();

  }
}
