import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, tap } from 'rxjs/operators';
//import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalDataService } from './global-data.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuestService {

  headers: HttpHeaders;
  sessionToken: '';

  constructor(
    public http: HttpClient,
    public gds: GlobalDataService
    ) {
      this.gds.getSessionToken().subscribe((data) => {
        console.log('Session Token OBSERVABLE', data);
        this.sessionToken = data;
      });

      this.headers = new HttpHeaders();
      this.headers = this.headers.set('Content-Type', 'application/json');
      this.headers = this.headers.set('X-DreamFactory-API-Key', environment.guestAPIKey);

      // this.getSessionToken();
      this.gds.getAgentId().subscribe(
        (data: any) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Agent Id as data is undefined');
          }
          else {
            console.log('Setting X-Exme-Agent-Id value');
            console.log(data);
            // ampm 20230629
            //this.headers = this.headers.set('X-Exme-Agent-Id', data.toString());
            this.headers = this.headerSet( this.headers,'X-Exme-Agent-Id', data.toString());
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );

      this.gds.getSessionToken().subscribe(
        (data: string) => {
          if (data === undefined || data===null || data==='') {
            console.log('Skipping Session Token as data is undefined');
          }
          else {
            console.log('X-ExME-Session-Token');
            // ampm 20230629
            //this.headers = this.headers.set('X-ExME-Session-Token', data);
            this.headers = this.headerSet( this.headers,'X-ExME-Session-Token', data);
            console.log('Current Header are');
            console.log(this.headers);
          }
        }
      );

//    this.headers = this.headers.set('Access-Control-Allow-Origin', '*');
   }

  /*
  loginDeviceAgent() {
    const url = `${environment.apiUrl}api/v2/user/session`;
    const body = {email: 'exmeDevice@exme.club', password: 'exmeDevice'};
    return this.http.post(url, body , {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }
  */

  postCrmAgentDevice(agent: any) {
    const body = {resource: [agent]};

    return this.localMakeRequest('postCrmAgentDevice',body,'ExMEPublic','postCrmAgentDevice',null,false);
/*
[postCrmAgentDevice]
target=_table/CRM_AGENT
method=post
service=ExMEdb
*/
/*    const url = environment.apiUrlExme + '_table/CRM_AGENT';

    return this.http.post(url, body, {headers: this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
  }

  getCrmGuestAgentByDeviceId(deviceId: string) {
    const body = {theDeviceId: deviceId };
    return this.localMakeRequest('getCrmGuestAgentByDeviceId',body,'ExMEPublic','getCrmGuestAgentByDeviceId',null,false);
/*


/*const url = `${environment.apiUrlExme}_table/CRM_AGENT?filter=device_id=${deviceId}`;
    // this.headers = this.headers.set('X-ExME-Session-Token', this.sessionToken);

    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
*/
  }

  getColListSimple(agentId: string) {


    let body = {theAgentId: agentId };
    return this.localMakeRequest('getColListSimple',body,'ExMESecure','getColListSimple',agentId,false);
/*
[getColListSimple]
target=_table/COL_CD_USER_COLLECTIONS
method=get
service=ExMEdb
sessionField=agent_id
filterField[]=agent_id##%3D##theAgentId
relatedField=COL_RD_COLLECTIONS_by_collection_id
staticFilter="(active%3D1)"

*/
/*
    const url = environment.apiUrlExme + `_table/COL_CD_USER_COLLECTIONS?related=COL_RD_COLLECTIONS_by_collection_id&filter=(agent_id%3D${agentId})and(active%3D1)`;
    return this.http.get(url, { headers: this.headers }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
    */
  }


  localMakeRequest(callFunction: string, body: any , service: string , operation: string, agent_id: string , deleteSession: boolean) {
    console.log('Post Body called from: ' + callFunction);
    console.log(JSON.stringify(body));

    const url = environment.apiUrl+ 'api/v2/' + service;
    console.log('Posting to URL: ' + url);
    // AMPM 20230629
    //let localHeader =  this.headers.append('X-Exme-Operation', operation).set('X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    let localHeader = this.headerSet(this.headers,'X-Exme-Operation', operation);
    console.log(localHeader);
    localHeader = this.headerSet(localHeader,'X-DreamFactory-API-Key', environment.xDreamFactoryAPIKeySecure);
    console.log(localHeader);
    if ( deleteSession ) {
      //localHeader = localHeader.delete('X-ExME-Session-Token');
      localHeader = this.headerSet(localHeader,'X-ExME-Session-Token',null);
      console.log(localHeader);
    }
    if ( agent_id != null && localHeader.get('X-Exme-Agent-Id') != agent_id ) {
      //localHeader = localHeader.delete('X-Exme-Agent-Id').set('X-Exme-Agent-Id',agent_id);
      localHeader = this.headerSet(localHeader,'X-Exme-Agent-Id',agent_id);
      console.log(localHeader);

    }
    console.log('Headers for call from ' + callFunction + ' are:');
    console.log(localHeader);
    return this.http.post(url, body, {headers: localHeader}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );

  }

  private headerSet(sourceHeader: HttpHeaders, changeKey: string, changeValue: any){
    let retHeader=new HttpHeaders();
    sourceHeader.keys().forEach( (key) => {
      if ( key !== changeKey){
        retHeader=retHeader.append(key,sourceHeader.get(key));
      } else {
      }
    });
    if ( changeValue !== undefined && changeValue !== null && changeValue !== '') {
      retHeader=retHeader.append(changeKey,changeValue);
//      console.log('Appending Key');
    }
//    console.log('Returning:');
//    console.log(retHeader);
    return retHeader;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `error message was: ${error.error.error.message}`);
    }
    return throwError('Something bad happened; please try again later.');
  }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

}
