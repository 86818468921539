/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'col-list',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/col-list/col-list.module').then( m => m.ColListPageModule)
      },
      {
        path: 'full-list',
        loadChildren: () => import('./pages/col-list/col-list.module').then( m => m.ColListPageModule)
      }
    ]
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'preferences',
    loadChildren: () => import('./pages/preferences/preferences.module').then( m => m.PreferencesPageModule)
  },
  {
    path: 'clubs',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/clubs/clubs.module').then( m => m.ClubsPageModule)
      },
      {
        path: ':id',
        children: [
          {
            path: 'club-members',
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/clubs/club-members/club-members.module').then( m => m.ClubMembersPageModule)
              },
            ]
          }
        ]
      },
      {
        path: 'club-add',
        loadChildren: () => import('./pages/clubs/club-add/club-add.module').then( m => m.ClubAddPageModule)
      }
    ]
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'col-admin',
    loadChildren: () => import('./pages/col-admin/col-admin.module').then( m => m.ColAdminPageModule)
  },
  {
    path: 'add-collection',
    loadChildren: () => import('./pages/add-collection/add-collection.module').then( m => m.AddCollectionPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'user-collection',
        children: [
      {
        path: '',
        loadChildren: () => import('./pages/user-collection/user-collection.module').then( m => m.UserCollectionPageModule)
      },
      {
        path: ':agent_id/:col_id/:user_col_id',
        children: [
          {
            path: 'col-items',
            children: [
              {
                path: ':agent_id/:col_id/:user_col_id',
                loadChildren: () => import('./pages/user-collection/user-collection-items/user-collection-items.module').then( m => m.UserCollectionItemsPageModule)
              },
              {
                path: 'card-list',
                loadChildren: () => import('./pages/user-collection/user-collection-items/card-list/card-list.module').then( m => m.CardListPageModule)
              },
              {
                path: '*',
                loadChildren: () => import('./pages/user-collection/user-collection-items/user-collection-items.module').then( m => m.UserCollectionItemsPageModule)
              },
            ]
          },
          {
            path: 'trade-options',
            children: [
              {
                path: ':agent_id/:col_id/:user_col_id',
                loadChildren: () => import('./pages/user-collection/trade-options/trade-options.module').then( m => m.TradeOptionsPageModule)
              },
              {
                path: '',
                loadChildren: () => import('./pages/user-collection/trade-options/trade-options.module').then( m => m.TradeOptionsPageModule)
              }
            ]
          },
          {
            path: 'trade-requests',
            children: [
              {
                path: ':agent_id/:col_id/:user_col_id',
                loadChildren: () => import('./pages/user-collection/trade-requests/trade-requests.module').then( m => m.TradeRequestsPageModule)
              },
              {
                path: ':agent_id/:col_id/:user_col_id/:trade_request_id',
                loadChildren: () => import('./pages/user-collection/trade-requests/trade-requests.module').then( m => m.TradeRequestsPageModule)
              },
              {
                path: '',
                loadChildren: () => import('./pages/user-collection/trade-requests/trade-requests.module').then( m => m.TradeRequestsPageModule)
              }
            ]
          },
          {
            path: 'col-manage',
            loadChildren: () => import('./pages/user-collection/user-collection-manage/user-collection-manage.module').then( m => m.UserCollectionManagePageModule)
          },
          {
            path: 'collection-rank',
            loadChildren: () => import('./pages/user-collection/collection-rank/collection-rank.module').then( m => m.CollectionRankPageModule)
          }
        ]
          }
    ]

  },

  {
    path: 'preferences',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/preferences/preferences.module').then(m => m.PreferencesPageModule)
      },
      {
        path: 'help',
        loadChildren: () => import('./pages/preferences/help/help.module').then(m => m.HelpPageModule)
      },
      {
        path: 'help/**',
        loadChildren: () => import('./pages/preferences/help/help.module').then(m => m.HelpPageModule)
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./pages/preferences/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
      },
      {
        path: 'my-favorites',
        loadChildren: () => import('./pages/preferences/my-favorites/my-favorites.module').then( m => m.MyFavoritesPageModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/preferences/profile/profile.module').then( m => m.ProfilePageModule)
      },
      {
        path: 'terms-of-use/:lang',
        loadChildren: () => import('./pages/preferences/terms-of-use/terms-of-use.module').then( m => m.TermsOfUsePageModule)
      },

    ]
  },
  {
    path: 'qr-code',
    loadChildren: () => import('./pages/qr-code/qr-code.module').then( m => m.QrCodePageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then( m => m.HistoryPageModule)
  },


]
;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
