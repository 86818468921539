// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
export const environment = {
  production: false,
  apiUrl: 'http://devapi.exme.club/',
  apiUrlExme: 'http://devapi.exme.club/api/v2/karddev/',
  XDreamFactoryAPIKey: '9a9c0bf24289b7cf187783c19194c118ade9e1582a4215def4a84c9042b61023',
  fileManagerAPIKey: 'b5cb82af7b5d4130f36149f90aa2746782e59a872ac70454ac188743cb55b0ba',
  guestAPIKey: '9a9c0bf24289b7cf187783c19194c118ade9e1582a4215def4a84c9042b61023',
  CollectionListUrl: 'http://dev.col.exme.club/',
};
*/

// PROD SETTINGS FOR EXME
/*
export const environment = {
  production: false,
  apiUrl: 'https://api.exme.club/',
  //apiUrlExme: 'https://lab.exme.club/api/v2/ExMEdb/',
  //XDreamFactoryAPIKey : 'be81485698713d1af9f11e220a9d0e6c505e47645970d3a889d657b401988c6a',
  xDreamFactoryAPIKeySecure:
    'abf8e9814a384fd76f7620084a914a21d635f84a766fa740a54305b9a8febccc',
  fileManagerAPIKey:
    'b5cb82af7b5d4130f36149f90aa2746782e59a872ac70454ac188743cb55b0ba',
  guestAPIKey:
    'be81485698713d1af9f11e220a9d0e6c505e47645970d3a889d657b401988c6a',
  collectionListUrl: 'https://col.exme.club/',
  getStickers: 'https://api.exme.club/getSticker.php',
};
*/
// LAB SETTINGS FOR EXME

  export const environment = {
   production: false,
   apiUrl: 'https://lab.exme.club/',
   //apiUrlExme: 'https://lab.exme.club/api/v2/ExMEdb/',
   //XDreamFactoryAPIKey : 'be81485698713d1af9f11e220a9d0e6c505e47645970d3a889d657b401988c6a',
   xDreamFactoryAPIKeySecure : '96c694800677dc644d9b2632d094914ada40f6d4b45e18f563feaf59c835c3f8',
   fileManagerAPIKey: 'b5cb82af7b5d4130f36149f90aa2746782e59a872ac70454ac188743cb55b0ba',
   guestAPIKey: 'be81485698713d1af9f11e220a9d0e6c505e47645970d3a889d657b401988c6a',
   collectionListUrl: 'https://col.exme.club/',
   getStickers: 'https://lab.exme.club/getSticker.php'
 };

/*
export const environment = {
  production: false,
  apiUrl: 'https://api.exme.club/',
  apiUrlExme: 'https://api.exme.club/api/v2/ExMEdb/',
  XDreamFactoryAPIKey : 'be81485698713d1af9f11e220a9d0e6c505e47645970d3a889d657b401988c6a',
  fileManagerAPIKey: 'b5cb82af7b5d4130f36149f90aa2746782e59a872ac70454ac188743cb55b0ba',
  guestAPIKey: 'be81485698713d1af9f11e220a9d0e6c505e47645970d3a889d657b401988c6a',
  CollectionListUrl: 'https://col.exme.club/',
};
*/

export const version = {
  appVersion: '4.1.3',
  buildNumber: '40103',
  buildTarget: 'Lab',
  buildDate: '2024/01/22 16:26',
  //buildPlatform: "cordova",
  buildPlatform: 'browser',
};

export const common = {
  colAvatarsLocation: 'api/v2/files/col_avatar/',
  userAvatarsLocation: 'api/v2/files/use_img/',
  groupLogoLocation: 'api/v2/files/group_logo/',
  assetsLocation: 'api/v2/files/assets/',
  exmeAdminEmail: 'contact@exme.club',
  exmeSupportEmail: 'support@exme.club',
  exmeNoReplyEmail: 'no-reply@exme.club',
  exmeRequestsEmail: 'requests@exme.club',
  encryptKey: 'poklashfdhhjh54k48g5hfgycb502jd620sjf8',
  authUser: 'authUser',
  guestUser: 'guestUser',
  trialUser: 'trialUser',
  //gaProperty: 'UA-112993883-1',
  gaProperty: 'G-9K5KSERYJH',
};

export const anonymousAgent = {
  ACTIVE: true,
  AGENT_GROUP: null,
  AGENT_ID: 58,
  AVATAR: null,
  COMPANY: null,
  CREATED_BY: null,
  CREATION_DATE: '2020-06-13 17:47:57',
  DF_ID: null,
  EMAIL: 'developing39@exme.club',
  FB_ID: null,
  FIRST_NAME: 'fdsfdssfd',
  LANGUAGE_ID: 1,
  LAST_NAME: 'fsfdssfd',
  LAST_UPDATE: '2020-06-13 17:47:57',
  PHONE_NO: null,
  PROFILE_ID: null,
  REGISTRATION_CODE: null,
  SALT: null,
  UPDATED_BY: null,
  USER_NAME: 'sfsfddf',
  USER_PASSWORD: null,
  allow_gdpr_contact_share: false,
  allow_mail_contact: false,
  country_cca2: 'ZZ',
  device_id: 'fsffsdfd',
  hide_other_col_trade_alert: false,
  is_cordova: false,
  is_internal: false,
  max_presented_app_message: 0,
  num_trade_evaluations: 0,
  over_18_yo: true,
  preferred_location_1: null,
  preferred_location_2: null,
  preferred_location_3: null,
  terms_accept_date: '2020-06-13 17:47:57',
  terms_accept_version: '1.0',
  trade_by_mail: false,
  trade_rating: null,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
